// Cửa sổ chatbox: https://codepen.io/emilcarlsson/pen/ZOQZaV
import React, { Component } from 'react';
import '../../assets/css/chatbox.css';
import {
    Empty, Row, Col,
    Typography, Tabs, Card,
    Descriptions, Divider, Input,
    TreeSelect, DatePicker, Button,
    Avatar, Spin, Tooltip, Tag, Upload,
    Modal,
    Drawer,
    Space,
    Image
} from 'antd';
import {
    LoadingOutlined,
    MessageOutlined,
    MailOutlined,
    ProfileOutlined,
    UserAddOutlined,
    UserOutlined,
    PhoneOutlined,
    GiftOutlined,
    HomeOutlined,
    EnvironmentOutlined,
    BarcodeOutlined,
    WomanOutlined,
    CommentOutlined,
    DeleteOutlined,
    LikeOutlined,
    EyeInvisibleOutlined,
    LinkOutlined,
    VideoCameraOutlined,
    FilterOutlined,
    EyeOutlined,
    EyeInvisibleFilled
} from '@ant-design/icons'
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import qs from 'qs';
import {
    getBase64, cleanObject, stripHtml,
    formatRelativeDate, makeTree, _newLine, handleAttachmentMessage,
    toNewLine, handleSnippet, CustomerScheduleType, customerGender, isValidDateForm, isValidDate
} from '../../utils/helpers';
import appConfig from '../../config';
import axios from 'axios';
import moment from 'moment';
import no_profile from '../../assets/img/no_profile.jpg';
import { NotificationManager } from 'react-notifications';
import messageSentSound from "../../assets/audio/message_sent.wav";
import messageReceivedSound from "../../assets/audio/message_received1.mp3";
import BaseSelect from '../../components/Elements/BaseSelect';
import { checkPermission } from '../../utils/permission';
import ChatSpecific from './ChatSpecific';
import ReactHtmlParser from 'react-html-parser';
import socketio from "socket.io-client";
import { isMobile } from 'react-device-detect';

// actions
import {
    getListConversation,
    getListMessages,
    updateHasRead
} from '../../redux/actions/ConversationsActions';
import { createCustomerFromChat, getCustomerByFacebookName, updateCustomer, updateCustomerFromChat } from '../../redux/actions/CustomerActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getListPageActive } from '../../redux/actions/PageManagerActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';

const { Paragraph } = Typography;
const { CheckableTag } = Tag;

const filterTags = [
    {
        value: 'messages',
        name: <><MailOutlined /> Tin nhắn</>
    },
    {
        value: 'comment',
        name: <><MessageOutlined /> Bình luận</>
    }
]

class Conversations extends Component {

    constructor(props) {
        super(props);
        var query = qs.parse(this.props.location.search.slice(1));
        this.state = {
            messageText: '',
            show_loading_send_msg: false,
            show_loading_list_msg: false,
            current_thread: query.thread ? query.thread : undefined,
            current_sender: query.sender ? query.sender : undefined,
            list_messages: [],
            districts: [],
            wards: [],
            currentCustomer: undefined,
            sender: undefined,
            currentpage: undefined,
            socket: socketio.connect(appConfig.SERVER_NODEJS_URL),
            is_delete: false,
            isOpenChat: false,
            currentData: null,
            isOpenFilter: false,
            read_data: null,
            isLoading: false
        }
        this.textInput = React.createRef();
        this.myFormRef = React.createRef();
        this.myFormSearchRef = React.createRef();
        this.myFormSearchValueRef = React.createRef();
        this.playerSentSoundRef = React.createRef();
        this.playerReceivedSoundRef = React.createRef();
    }

    async componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ show_loading_list_msg: true });
        this.props.getListPageActive();
        await this.props.getListConversation(query).then(async () => {
            this.setState({ show_loading_list_msg: false });
        })

        if (query.thread) {
            await this.props.getListMessages({ thread_id: query.thread, sender_id: query.sender, type: query.type }).then((res) => {
                if (res.data.sender) {
                    this.props.getCustomerByFacebookName({ fb_name: res.data.sender.sender_name }).then(response => {
                        this.setState({ currentCustomer: response });
                    })
                }
                this.setState({ list_messages: res.data, sender: res.data.sender, currentpage: res.data.currentpage, read_data: res.data.read_data, show_loading_list_msg: false });
            })
        }

        this.props.getListProvinces();
        this.props.getBranchActive();
        this.props.getCustomerSourceActive();

        // Nhận event từ webhook facebook
        this.state.socket.on('post_to_client', (data) => { // Viết dạng Arrow function mới gọi được this bên trong
            console.log("Socket event.....");
            //console.log("res_socket: ", data)
            try {
                if (data.messages.is_sender == 'customer') {
                    this.playerReceivedSound();
                }
            } catch (err) {
                console.log("error: ", err)
            }

            this.props.getListConversation().then(() => {
                this.setState({ show_loading_list_msg: false });
            })
            if (data.sender) {
                this.props.getCustomerByFacebookName({ fb_name: data.sender.sender_name }).then(response => {
                    this.setState({ currentCustomer: response });
                })
            }
            var { current_thread, current_sender, list_messages } = this.state;

            if (data.messages.type == 'messages') {

                this.setState({ read_data: data.read_data });

                if (list_messages && data.messages.is_sender != 'page' && data.messages.thread_id == current_thread) {
                    this.setState({
                        list_messages: {
                            messages: [...list_messages.messages, data.messages],
                            sender: data.sender,
                            currentpage: data.currentpage
                        },
                        show_loading_list_msg: false
                    });
                }
                if (list_messages && data.messages.is_sender == 'page' && data.messages.thread_id == current_thread) {
                    if (data.messages.attachment_type == 'file' || data.messages.attachment_type == 'video' || data.messages.attachment_type == 'audio') {
                        this.setState({
                            list_messages: {
                                messages: [...list_messages.messages, data.messages],
                                sender: data.sender,
                                currentpage: data.currentpage
                            },
                            show_loading_list_msg: false
                        });
                    }
                }
            }
            if (data.messages.type == 'comment') {
                if (list_messages && data.messages.is_sender != 'page' && data.messages.thread_id == current_thread && data.messages.group_from_id == current_sender) {
                    this.setState({
                        list_messages: {
                            messages: [...list_messages.messages, data.messages],
                            sender: data.sender,
                            currentpage: data.currentpage
                        },
                        show_loading_list_msg: false
                    });
                }
            }
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        var query = qs.parse(nextProps.location.search.slice(1));
        if (nextProps.location.search !== this.props.location.search.slice()) {
            delete query.sender;
            delete query.type;
            this.props.getListConversation(query);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.scrollToBottom()
        if (prevState.currentCustomer != this.state.currentCustomer) {
            if (this.state.currentCustomer) {
                this.props.getListDistricts({ province_code: this.state.currentCustomer.province_code }).then(res => {
                    this.setState({ districts: res.data });
                })
                this.props.getListWards({ district_code: this.state.currentCustomer.district_code }).then(res2 => {
                    this.setState({ wards: res2.data })
                })
            }
        }
    }

    scrollToBottom() {
        const element = document.getElementById('messages');
        if (element) {
            element.scrollIntoView({ block: "end" });
        }
    }

    async loadMessages(thread_id, msg_id, type, sender_id) {
        this.onUpdateHasRead(msg_id);
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ show_loading_list_msg: true, current_thread: thread_id, current_sender: sender_id });
        var thread = 'thread', sender = 'sender', type_name = 'type';
        query = { ...query, [thread]: thread_id }
        query = { ...query, [sender]: sender_id }
        query = { ...query, [type_name]: type }

        //console.log("query: ", query)
        query = cleanObject(query); // remove empty fields
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });

        this.scrollToBottom()
        await this.props.getListMessages({ thread_id: thread_id, type: type, sender_id: sender_id }).then((res) => {
            if (res.data.sender) {
                this.props.getCustomerByFacebookName({ fb_name: res.data.sender.sender_name }).then(response => {
                    this.setState({ currentCustomer: response });
                })
            }
            this.setState({ list_messages: res.data, sender: res.data.sender, currentpage: res.data.currentpage, read_data: res.data.read_data, show_loading_list_msg: false });
        })
    }

    // Gửi file từ local cho user
    sendMessageAttachment = async (e, fileType) => {
        const data = new FormData();
        var { currentpage, sender } = this.state;
        var query = qs.parse(this.props.location.search.slice(1));
        var page_id = currentpage.page_id;
        this.setState({ show_loading_send_msg: true })

        if (fileType == 'image') {
            var preview = await getBase64(e.file);
            const new_msg = {
                thread_id: query.thread,
                message: preview,
                from_name: currentpage.page_name,
                from_id: page_id,
                from_picture: currentpage.page_picture,
                created_time: moment(),
                message_type: 'attachments',
                attachment_type: fileType,
                type: 'messages',
                is_sender: 'page',
                is_delete: null
            }
            this.setState({
                list_messages: {
                    messages: [...this.state.list_messages.messages, new_msg],
                    sender: this.state.list_messages.sender
                }
            })
        }

        var attachment = {
            attachment: {
                type: fileType,
                payload: {
                    is_reusable: true
                }
            }
        }
        var recipient = {
            id: sender.sender_id
        }
        data.append('recipient', JSON.stringify(recipient));
        data.append('message', JSON.stringify(attachment));
        data.append('filedata', e.file);

        var access_token_page = currentpage.page_access_token;
        var url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/me/messages?access_token=${access_token_page}`;

        const config = {
            headers: { "content-type": "multipart/form-data" }
        };
        axios({
            method: "post",
            url: url,
            data,
            config
        }).then(response => {
            if (response.status == 200) {
                this.setState({ show_loading_send_msg: false })
                console.log("Send file data successfully: ", response.data);
                this.playerSendSound();
            }
        }).catch(function (error) {
            if (error.response) {
                NotificationManager.error(error.response.data.error.message);
            }
        });

        // Gửi file bằng url, chưa dùng đến, đã test ok
        // var fileType = 'image'; // image, audio, file, video, template => https://developers.facebook.com/docs/messenger-platform/send-messages/templates
        // // Kích thước tối đa của file đính kèm là 25MB
        // const msgFile = {
        //     recipient: {
        //         id: sender.sender_id
        //     },
        //     message: {
        //         attachment: {
        //             type: fileType,
        //             payload: {
        //                 url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png",
        //                 is_reusable: true
        //             }
        //         }
        //     }
        // }
        // axios({
        //     method: 'post',
        //     url: `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/me/messages?access_token=${access_token_page}`,
        //     headers: {},
        //     data: msgFile
        // }).then(response => {
        //     if (response.status == 200) {
        //         this.setState({ show_loading_send_msg: false })
        //         console.log("Send file successfully: ", response.data);
        //         this.playerSendSound();
        //     }
        // }).catch(function (error) {
        //     if (error.response) {
        //         NotificationManager.error(error.response.data.error.message);
        //     }
        // });
    }

    // Gửi tin nhắn cho user trên page
    submitMessage(e) {
        e.preventDefault();
        var value = stripHtml(this.textInput.current.value).trim();
        value = value.replace(/\&|_/gi, 'và'); // Xóa dấu &
        value = value.replace(/\#|_/gi, '$'); // Xóa dấu #
        var query = qs.parse(this.props.location.search.slice(1));
        var { currentpage, sender } = this.state;
        //console.log("sender: ", sender)

        if (value != '' && value.trim().length) {
            this.textInput.current.value = '';
            this.textInput.current.focus();
            this.textInput.current.style.height = "auto";
            localStorage.removeItem('messageText');
            var recipientObj = { id: sender.sender_id }
            var recipient = JSON.stringify(recipientObj);
            var messageObj = { text: value };
            var message = JSON.stringify(messageObj);
            var access_token_page = currentpage.page_access_token;
            var page_id = currentpage.page_id;
            var comment_id = sender.recipient_id;
            //console.log("comment_id: ", comment_id)
            let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${page_id}/messages?recipient=${recipient}&messaging_type=RESPONSE&message=${message}&access_token=${access_token_page}`;
            if (sender.type == 'comment') {
                url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}/comments?message=${value}&access_token=${access_token_page}`;
            }
            this.setState({ show_loading_send_msg: true })

            const new_msg = {
                thread_id: query.thread,
                message: value,
                from_name: currentpage.page_name,
                from_id: page_id,
                from_picture: currentpage.page_picture,
                created_time: moment(),
                message_type: 'text',
                is_sender: 'page',
                is_delete: null
            }
            this.setState({
                list_messages: {
                    messages: [...this.state.list_messages.messages, new_msg],
                    sender: this.state.list_messages.sender
                }
            })

            axios.post(url).then((response) => { // Viết dạng Arrow function mới gọi được this bên trong
                if (response.status == 200) {
                    this.setState({ show_loading_send_msg: false })
                    console.log("Send successfully: ", response.data);
                    this.playerSendSound();
                }
            }).catch(function (error) {
                if (error.response) {
                    NotificationManager.error(error.response.data.error.message);
                }
            });
        }
    }

    onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.myFormRef.requestSubmit();
            localStorage.removeItem('messageText')
        }
    }

    setLocalStorage() {
        var value = stripHtml(this.textInput.current.value).trim();
        if (value != '' && value.trim().length) {
            if (localStorage) {
                localStorage.setItem('messageText', value)
            }
        } else {
            localStorage.removeItem('messageText')
        }
    }

    handleInput(e) {
        if (this.textInput.current) {
            this.textInput.current.style.height = "auto";
            this.textInput.current.style.height = `${e.target.scrollHeight - 16}px`;
        }
    };

    playerSendSound() {
        this.playerSentSoundRef.current.play();
    }
    playerReceivedSound() {
        this.playerReceivedSoundRef.current.play();
    }

    onUpdateHasRead(msg_id) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (msg_id) {
            this.props.updateHasRead(msg_id, 1);
            this.props.getListConversation(query)
        }
    }

    async submitCustomer(e) {
        e.preventDefault();
        //console.log("sender: ", this.state.sender)
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { currentCustomer, sender, currentpage, current_thread } = this.state;

                    const customer = {
                        gender: values.gender,
                        full_name: values.full_name,
                        phone: values.phone,
                        province_code: values.province_code,
                        district_code: values.district_code,
                        customer_class_id: values.customer_class_id,
                        customer_status_id: values.customer_status_id,
                        fb_Interactive_status_id: values.fb_Interactive_status_id,
                        fb_Interactive_type_id: values.fb_Interactive_type_id,
                        note: values.note,
                        mode: "from_message",
                        fb_source: `https://www.facebook.com/${currentpage.page_id}`,
                        fb_link: "https://facebook.com",
                        customer_source_id: values.customer_source_id,
                        branch_management_id: values.branch_management_id,
                        current_thread: current_thread
                    }

                    if (sender != undefined) {
                        customer.fb_psid = sender.sender_id;
                        customer.fb_name = sender.sender_name;
                        customer.sender_picture = sender.sender_picture;
                    }

                    const services_interested = { // Dịch vụ quan tâm
                        service_id: values.interested_service_id
                    }

                    const services_selected = { // Dịch vụ chốt
                        service_id: values.selected_service_id
                    }

                    const appointment_schedule = {
                        name: values.appointment_schedule_name,
                        time: values.appointment_schedule_time ? values.appointment_schedule_time.format("YYYY-MM-DD HH:mm") : null,
                    }

                    values.customer = customer;
                    values.services_interested = services_interested;
                    values.services_selected = services_selected;
                    values.appointment_schedule = appointment_schedule;

                    delete values.interested_service_id;
                    delete values.selected_service_id;
                    delete values.appointment_schedule_name;
                    delete values.appointment_schedule_time;

                    delete values.full_name;
                    delete values.phone;
                    delete values.province_code;
                    delete values.district_code;
                    delete values.customer_class_id;
                    delete values.customer_status_id;
                    delete values.fb_Interactive_status_id;
                    delete values.fb_Interactive_type_id;
                    delete values.note;
                    delete values.gender;
                    delete values.branch_management_id;
                    delete values.customer_source_id;

                    if (currentCustomer != undefined) {
                        customer.id = currentCustomer?.id;
                        customer.code = currentCustomer?.code;
                        appointment_schedule.id = currentCustomer.appointment_schedule?.id;
                        services_interested.id = currentCustomer.services_interested?.id;
                        services_selected.id = currentCustomer.services_selected?.id;
                        await this.props.updateCustomerFromChat(values).then(res => {
                            this.setState({ isLoading: false });
                        });
                        //console.log("currentCustomer from: ", currentCustomer)
                    } else {
                        await this.props.createCustomerFromChat(values).then(res => {
                            this.setState({ isLoading: false });
                        });
                    }
                    //console.log(values);

                    if (sender != undefined) {
                        this.props.getCustomerByFacebookName({ fb_name: sender.sender_name }).then(response => {
                            this.setState({ currentCustomer: response });
                        })
                    }
                    this.props.form.resetFields();

                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                //this.props.form.resetFields(['district_code', 'wards_code']);
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                //this.props.form.resetFields(['wards_code']);
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    onSearchConversation = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.myFormSearchRef.requestSubmit();
        }
    }

    onChangeSearchConversation = () => {
        var keyword = stripHtml(this.myFormSearchValueRef.current.value).trim();
        if (keyword == '') {
            var query = qs.parse(this.props.location.search.slice(1));
            delete query.keyword
            query = cleanObject(query);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: qs.stringify(query)
            });
        }
    }

    submitSearchConversation(e) {
        e.preventDefault();
        var keyword = stripHtml(this.myFormSearchValueRef.current.value).trim();
        if (keyword != '' && keyword.trim().length) {
            var query = qs.parse(this.props.location.search.slice(1));
            var name = "keyword";
            query = {
                ...query,
                [name]: keyword
            }
            query = cleanObject(query);
            this.props.history.push({
                pathname: this.props.location.pathname,
                search: qs.stringify(query)
            });
        }
    }

    deleteComment(comment_id) {
        var access_token_page = this.state.currentpage.page_access_token;
        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}?method=delete&access_token=${access_token_page}`;
        var self = this;
        axios.delete(url).then((response) => {
            if (response.status == 200) {
                console.log("Delete comment successfully: ", response.data);
                if (response.data.success) {
                    self.setState({ is_delete: true })
                    NotificationManager.success("Bình luận đã được xóa...");
                }
            }
        }).catch(function (error) {
            if (error.response) {
                self.setState({ is_delete: false })
                //NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    toogleHideComment(verb, comment_id) {
        var access_token_page = this.state.currentpage.page_access_token;
        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}?access_token=${access_token_page}&is_hidden=${verb}`;
        var verbText = "Hiện bình luận thành công....";
        if (verb) {
            verbText = "Ẩn bình luận thành công...."
        }
        axios.post(url).then((response) => {
            if (response.status == 200) {
                console.log(`Update hide/unhide comment successfully: `, response.data);
                if (response.data.success) {
                    NotificationManager.success(verbText);
                }
            }
        }).catch(function (error) {
            if (error.response) {
                //NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    likeComment(comment_id) {
        var access_token_page = this.state.currentpage.page_access_token;
        let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${comment_id}/likes?access_token=${access_token_page}`;
        axios.post(url).then((response) => {
            if (response.status == 200) {
                console.log(`Like comment successfully: `, response.data);
                if (response.data.success) {
                    NotificationManager.success("Like comment successfully...");
                }
            }
        }).catch(function (error) {
            if (error.response) {
                //NotificationManager.error(error.response.data.error.message);
            }
        });
    }

    toggleOpenChat(isOpenChat, psid = null, thread_id = null) {
        this.setState({ isOpenChat: isOpenChat, currentData: { psid: psid, thread_id: thread_id } });
    }

    onCheckTag(value, checked) {
        //console.log(value, checked)
        var query = qs.parse(this.props.location.search.slice(1));
        var temp = query.filter_type ? query.filter_type.split(',') : [];
        if (checked) temp.push(value);
        else temp = temp.filter(item => item != value);
        this.onChangeFilter('filter_type', temp.join(','));
    }

    onCheckTagFilterPage(value, checked) {
        //console.log(value, checked)
        var query = qs.parse(this.props.location.search.slice(1));
        var temp = query.filter_page ? query.filter_page.split(',') : [];
        if (checked) temp.push(value);
        else temp = temp.filter(item => item != value);
        this.onChangeFilter('filter_page', temp.join(','));
    }

    onChangeFilter(name, value) {
        //console.log(value)
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    render() {
        var {
            list_messages,
            sender,
            show_loading_send_msg,
            current_thread,
            current_sender,
            districts,
            wards,
            currentCustomer,
            currentpage,
            show_loading_list_msg,
            isOpenChat,
            currentData,
            isOpenFilter,
            read_data,
            isLoading
        } = this.state;
        var { list_conversations,
            customerClassActiveList,
            customerStatusActiveList,
            service_active_list,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            list_current_page,
            list_page_active,
            branchActiveList,
            customer_source_active_list
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);
        var query = qs.parse(this.props.location.search.slice(1));
        var selectedTags = query.filter_type ? query.filter_type.split(',') : [];
        var selectedFilterPage = query.filter_page ? query.filter_page.split(',') : [];
        //console.log("list_conversations: ", list_conversations)
        //console.log("currentCustomer: ", currentCustomer)
        //console.log("current_thread: ", current_thread)

        //console.log("list_messages: ", list_messages);

        return (
            <Card size='small' className='crad-messenger'>
                <Row gutter={[15, 15]}>
                    <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                        <Row gutter={[15, 15]} className="row-message">
                            <Col className='col-contacts' xs={8} sm={8} md={8} lg={8} xl={8}>
                                <div id="sidepanel">
                                    <div id="search">
                                        <form onSubmit={(e) => this.submitSearchConversation(e)} ref={el => this.myFormSearchRef = el}>
                                            <label htmlFor><i className="fa fa-search" aria-hidden="true" /></label>
                                            <input type="text" ref={this.myFormSearchValueRef} onChange={this.onChangeSearchConversation} onKeyDown={this.onSearchConversation} placeholder="Nhập tên khách hàng, tên fanpage..." />
                                        </form>
                                    </div>
                                    <Row className="mt-2 mb-2" align="middle">
                                        {
                                            filterTags.map(tag => (
                                                <CheckableTag
                                                    key={tag.value}
                                                    checked={selectedTags.indexOf(tag.value) > -1}
                                                    onChange={checked => this.onCheckTag(tag.value, checked)}
                                                    style={{ fontWeight: '500' }}
                                                >
                                                    {tag.name}
                                                </CheckableTag>
                                            ))
                                        }
                                        {
                                            list_current_page && list_current_page.length ? (
                                                <>
                                                    <Avatar.Group size={25} maxCount={3} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                                                        {
                                                            list_current_page.map(item => {
                                                                return (
                                                                    <Tooltip title={item.page_name} placement="top">
                                                                        <Avatar icon={<UserOutlined />} src={item.page_picture} />
                                                                    </Tooltip>
                                                                )
                                                            })
                                                        }
                                                    </Avatar.Group>
                                                    <Tooltip title="Lọc theo Trang" placement="top">
                                                        <FilterOutlined onClick={() => this.setState({ isOpenFilter: true })} className='filter-icon-conversation' />
                                                    </Tooltip>
                                                </>
                                            ) : null
                                        }
                                    </Row>
                                    <div id="contacts">
                                        <ul>
                                            {
                                                list_conversations && list_conversations.length ? (
                                                    <>
                                                        {
                                                            list_conversations.map(item => {
                                                                return (
                                                                    <li className={(current_thread == item.thread_id || query.thread == item.thread_id) && (item.sender_id == current_sender || query.sender == item.sender_id) ? 'active contact' : 'contact'} onClick={() => this.loadMessages(item.thread_id, item.snippet.message_id, item.snippet.type, item.sender_id)}>
                                                                        <div className="wrap">
                                                                            {item.sender_picture ? (
                                                                                <Avatar icon={<UserOutlined />} className='avata' size={40} src={item.sender_picture} />
                                                                            ) : (
                                                                                <Avatar icon={<UserOutlined />} className='avata' size={40} src={no_profile} />
                                                                            )}
                                                                            <div className="meta">
                                                                                <p className={item.snippet && item.snippet.has_read == 0 ? 'name active' : 'name'}>{item.sender_name} <div className={item.snippet && item.snippet.has_read == 0 ? 'date-time active' : 'date-time'}>{formatRelativeDate(item.updated_time)}</div></p>
                                                                                <p className={item.snippet && item.snippet.has_read == 0 ? 'preview active' : 'preview'}>{item.snippet && item.snippet.is_sender == 'page' ? <i className="fas fa-reply ng-scope icon-fa-reply"></i> : <i class="fa-solid fa-share icon-fa-share"></i>}
                                                                                    {
                                                                                        item.snippet && item.snippet.message_type == 'attachments' ? (
                                                                                            <>&nbsp;
                                                                                                {
                                                                                                    handleSnippet(item.snippet.is_sender, item.snippet.attachment_type)
                                                                                                }
                                                                                            </>
                                                                                        ) : (
                                                                                            <>&nbsp;{stripHtml(item.snippet && item.snippet.message)}</>
                                                                                        )
                                                                                    }

                                                                                </p>
                                                                                <p className={item.snippet && item.snippet.has_read == 0 ? 'mess-icon active' : 'mess-icon'}>
                                                                                    {
                                                                                        item.type == 'comment' ? (
                                                                                            <CommentOutlined />
                                                                                        ) : (
                                                                                            <MailOutlined />
                                                                                        )
                                                                                    }
                                                                                    <p style={{ marginTop: '-10px', marginLeft: '2px' }}>
                                                                                        <Tooltip placement="top" title={item.page_name}>
                                                                                            <Avatar icon={<UserOutlined />} size={16} src={item.page_picture} />
                                                                                        </Tooltip>
                                                                                    </p>
                                                                                </p>
                                                                            </div>
                                                                            {
                                                                                item.snippet && item.snippet.has_read == 0 ? (
                                                                                    <span className='online'></span>
                                                                                ) : null
                                                                            }

                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                ) : (
                                                    <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center' }} key={1}>
                                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                    </Row>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col className='col-content' xs={16} sm={16} md={16} lg={16} xl={16}>
                                <div className="content">
                                    <div className="contact-profile">
                                        {
                                            sender != undefined ? (
                                                <>
                                                    {sender.sender_picture ? (
                                                        <Avatar className='avata' icon={<UserOutlined />} size={40} src={sender.sender_picture} />
                                                    ) : (
                                                        <Avatar className='avata' icon={<UserOutlined />} size={40} src={no_profile} />
                                                    )}
                                                    <p style={{ margin: 0, lineHeight: '18px', marginTop: '10px' }}>
                                                        <Paragraph style={{ margin: 0 }} copyable>{sender.sender_name}</Paragraph>
                                                        {
                                                            read_data?.type == 'messages' ? (
                                                                <small style={{ fontWeight: '500' }}>
                                                                    {
                                                                        read_data?.is_read == 1 && read_data.is_read_time ? (
                                                                            <><EyeOutlined /> Đã xem lúc&nbsp;
                                                                                {
                                                                                    read_data && read_data.is_read_time ? moment(read_data.is_read_time).format('DD/MM/YYYY HH:mm') : null
                                                                                }
                                                                            </>
                                                                        ) : (
                                                                            <><EyeInvisibleFilled /> Chưa xem</>
                                                                        )
                                                                    }
                                                                </small>
                                                            ) : null
                                                        }
                                                    </p>
                                                    {
                                                        sender.type == 'comment' ? (
                                                            <Tooltip placement="top" title="Xem trên facebook">
                                                                <a style={{ float: 'right', fontSize: '20px', lineHeight: '20px' }} href={sender.post.permalink_url} target="_blank">
                                                                    <LinkOutlined className='icon' />
                                                                </a>
                                                            </Tooltip>
                                                        ) : null
                                                    }
                                                    {
                                                        currentpage && currentpage != undefined ? (
                                                            <Tooltip placement="top" title="Truy cập trang">
                                                                <a style={{ float: 'right', fontWeight: '500', lineHeight: '20px' }} href={`https://www.facebook.com/${currentpage.page_id}`} target="_blank">{currentpage.page_name}</a>
                                                                <span style={{ float: 'right', clear: 'both', lineHeight: '20px', marginTop: '-25px' }}>{currentpage.page_id}</span>
                                                            </Tooltip>
                                                        ) : null
                                                    }
                                                </>
                                            ) : null
                                        }
                                    </div>
                                    {
                                        list_messages && list_messages.messages && list_messages.messages.length ? (
                                            <>
                                                {sender.type == 'comment' ? (
                                                    <>
                                                        {
                                                            show_loading_list_msg ? (
                                                                <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center', marginRight: 0 }} key={1}>
                                                                    <Spin />
                                                                </Row>
                                                            ) : (
                                                                <div className="messages">
                                                                    <ul id='messages-ul'>
                                                                        <div className='content-post'>
                                                                            {sender.post && sender.post.message != undefined ? (
                                                                                <>{ReactHtmlParser(toNewLine(sender.post.message))}</>
                                                                            ) : (
                                                                                <div style={{ color: 'red' }}>Bài viết không tồn tại</div>
                                                                            )}
                                                                        </div>
                                                                        {
                                                                            list_messages.messages.map(item => { // sent, replies
                                                                                return (
                                                                                    <li className={item.is_sender == 'page' ? 'replies comment' : 'sent comment'}>
                                                                                        {item.from_picture ? (
                                                                                            // <img src={item.from_picture} alt="" />
                                                                                            <Avatar icon={<UserOutlined />} className='avata' size={22} src={item.from_picture} />
                                                                                        ) : (
                                                                                            <img src={no_profile} alt="" />
                                                                                        )}
                                                                                        <div>
                                                                                            {
                                                                                                item.message_type == 'text' ? (
                                                                                                    <>
                                                                                                        {ReactHtmlParser(toNewLine(item.message))}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {item.captions ? item.captions : null}
                                                                                                        {handleAttachmentMessage(item.attachment_type, item.message)}
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                            <div class="hover-info">
                                                                                                <div class="hover-time">
                                                                                                    {item.from_name}, {formatRelativeDate(item.created_time)}
                                                                                                </div>
                                                                                                <div className='icon-action-msg'>
                                                                                                    {
                                                                                                        item.is_sender == 'page' ? (
                                                                                                            <div style={{ textAlign: 'right' }}>
                                                                                                                {
                                                                                                                    item.is_delete == 1 ? (
                                                                                                                        <span style={{ color: 'red' }}>Đã xóa</span>
                                                                                                                    ) : (
                                                                                                                        <Tooltip placement="top" title="Xóa">
                                                                                                                            <DeleteOutlined onClick={() => this.deleteComment(item.message_id)} className='icon delete' id={item.message_id} />
                                                                                                                        </Tooltip>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div>
                                                                                                                {
                                                                                                                    item.is_delete == 1 ? (
                                                                                                                        <span style={{ color: 'red' }}>Đã xóa &nbsp;</span>
                                                                                                                    ) : (
                                                                                                                        <Tooltip placement="top" title="Xóa">
                                                                                                                            <DeleteOutlined onClick={() => this.deleteComment(item.message_id)} className='icon delete' id={item.message_id} />
                                                                                                                        </Tooltip>
                                                                                                                    )
                                                                                                                }
                                                                                                                <Tooltip placement="top" title="Like">
                                                                                                                    <LikeOutlined onClick={() => this.likeComment(item.message_id)} className={`${item.reaction_type ? 'icon like active' : 'icon like'}`} />
                                                                                                                </Tooltip>
                                                                                                                {
                                                                                                                    item.is_hidden == 1 ? (
                                                                                                                        <Tooltip placement="top" title="Đang ẩn, click để hiện">
                                                                                                                            <EyeInvisibleOutlined onClick={() => this.toogleHideComment(false, item.message_id)} className='icon eye active' />
                                                                                                                        </Tooltip>
                                                                                                                    ) : (
                                                                                                                        <Tooltip placement="top" title="Ẩn">
                                                                                                                            <EyeInvisibleOutlined onClick={() => this.toogleHideComment(true, item.message_id)} className='icon eye' />
                                                                                                                        </Tooltip>
                                                                                                                    )
                                                                                                                }
                                                                                                                <Tooltip placement="top" title="Nhắn tin">
                                                                                                                    <MailOutlined onClick={() => this.toggleOpenChat(true, item.from_id, item.thread_id)} className='icon mail' />
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                        <li id='messages'></li>
                                                                    </ul>
                                                                </div>
                                                            )
                                                        }
                                                    </>

                                                ) : null}

                                                {sender.type == 'messages' ? (
                                                    <>
                                                        {show_loading_list_msg ? (
                                                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center', marginRight: 0 }} key={1}>
                                                                <Spin />
                                                            </Row>
                                                        ) : (
                                                            <div className="messages">
                                                                <ul id='messages-ul'>
                                                                    {
                                                                        list_messages.messages.map(item => { // sent, replies
                                                                            return (
                                                                                <li className={item.is_sender == 'page' ? 'replies' : 'sent'}>
                                                                                    {item.from_picture ? (
                                                                                        //<img src={item.from_picture} alt="" />
                                                                                        <Avatar icon={<UserOutlined />} className='avata' size={22} src={item.from_picture} />
                                                                                    ) : (
                                                                                        <img src={no_profile} alt="" />
                                                                                    )}
                                                                                    <div>
                                                                                        {
                                                                                            item.message_type == 'text' ? (
                                                                                                <>{ReactHtmlParser(toNewLine(item.message))}</>
                                                                                            ) : (
                                                                                                <>{handleAttachmentMessage(item.attachment_type, item.message)}</>
                                                                                            )
                                                                                        }
                                                                                        <div class="hover-info">
                                                                                            <div class="hover-time">
                                                                                                {formatRelativeDate(item.created_time)}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                    <li id='messages'></li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </>

                                                ) : null}
                                                <div className="message-input">
                                                    <LoadingOutlined style={{ display: show_loading_send_msg ? 'block' : 'none' }} className='loading-messages' />
                                                    <div className="wrap">
                                                        <form onSubmit={(e) => this.submitMessage(e)} ref={el => this.myFormRef = el}>
                                                            {currentpage != undefined && currentpage.page_picture ? (
                                                                <Tooltip placement="top" title={currentpage.page_name}>
                                                                    <Avatar icon={<UserOutlined />} className='avata-page' size={30} src={currentpage.page_picture} />
                                                                    {/* <img className='avata-page' src={currentpage.page_picture} /> */}
                                                                </Tooltip>
                                                            ) : (
                                                                <img className='avata-page' src={no_profile} alt="" />
                                                            )}

                                                            <textarea
                                                                ref={this.textInput}
                                                                onKeyDown={this.onEnterPress}
                                                                onKeyUp={() => this.setLocalStorage()}
                                                                placeholder="Nhấn Enter để gửi, Shift + Enter để xuống dòng. Mỗi tin không quá 2000 ký tự..."
                                                                rows={1}
                                                                type="text"
                                                                onInput={(e) => this.handleInput(e)}
                                                            >
                                                                {localStorage.getItem('messageText')}
                                                            </textarea>

                                                            <div className='list-attachment-icon-wrap'>
                                                                <Tooltip placement="top" title="Gửi ảnh, dung lượng không quá 2MB">
                                                                    <Upload
                                                                        showUploadList={false}
                                                                        accept="image/*"
                                                                        customRequest={(e) => this.sendMessageAttachment(e, 'image')}
                                                                    >
                                                                        <i class="fas fa-image icon picture" aria-hidden="true"></i>
                                                                    </Upload>
                                                                </Tooltip>
                                                                <Tooltip placement="top" title="Gửi tài liệu, dung lượng không quá 2MB">
                                                                    <Upload
                                                                        showUploadList={false}
                                                                        accept=".xlsx, .xls, .doc, .docx, .txt, .pdf"
                                                                        customRequest={(e) => this.sendMessageAttachment(e, 'file')}
                                                                    >
                                                                        <i className="fa fa-paperclip icon attachment" aria-hidden="true" />
                                                                    </Upload>
                                                                </Tooltip>
                                                                <Tooltip placement="top" title="Gửi file âm thanh, dung lượng không quá 2MB">
                                                                    <Upload
                                                                        showUploadList={false}
                                                                        accept="audio/*"
                                                                        customRequest={(e) => this.sendMessageAttachment(e, 'audio')}
                                                                    >
                                                                        <i class="fa fa-file-audio icon" aria-hidden="true"></i>
                                                                    </Upload>
                                                                </Tooltip>
                                                                <Tooltip placement="top" title="Gửi video, dung lượng không quá 2MB">
                                                                    <Upload
                                                                        showUploadList={false}
                                                                        accept="video/*"
                                                                        customRequest={(e) => this.sendMessageAttachment(e, 'video')}
                                                                    >
                                                                        <VideoCameraOutlined className="icon" />
                                                                    </Upload>
                                                                </Tooltip>
                                                                <Tooltip placement="top" title="Gửi tin nhắn">
                                                                    <button type='submit' className="submit" onClick={(e) => this.submitMessage(e)}>
                                                                        <i className="fa fa-paper-plane icon" aria-hidden="true" />
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'flex-end', marginRight: 0 }} key={1}>
                                                <Empty
                                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                    description={<span style={{ fontSize: '1em' }}><MessageOutlined /> Chọn 1 hội thoại từ danh sách bên trái</span>}
                                                />
                                            </Row>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row >
                    </Col >
                    {
                        list_messages && list_messages.messages && list_messages.messages.length ? (
                            <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                <Card size='small' style={{ height: '85vh', paddingRight: 0 }}>
                                    <div className='wrap-chat-info'>
                                        <Tabs centered={true} size='small' defaultActiveKey="info">
                                            <Tabs.TabPane key="info" tab={<span><ProfileOutlined />Thông tin</span>}>
                                                <Divider orientationMargin={0} orientation="left" className="no-margin">Thông tin trên hệ thống</Divider>
                                                {
                                                    currentCustomer != undefined ? (
                                                        <Descriptions column={24} size="small">
                                                            <Descriptions.Item span={24}>
                                                                <UserOutlined /> <b>Tên:</b>&nbsp; <Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{currentCustomer.full_name}</Paragraph>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item span={24}>
                                                                <BarcodeOutlined /> <b>Mã:</b>&nbsp; <Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{currentCustomer.code}</Paragraph>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item span={24}>
                                                                <PhoneOutlined /><b> Điện thoại:</b>&nbsp; <Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{currentCustomer.phone}</Paragraph>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item span={24}>
                                                                <GiftOutlined /> <b>Năm sinh:</b> {isValidDate(currentCustomer.birthday)}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item span={24}>
                                                                <HomeOutlined /> <b>Địa chỉ:</b>&nbsp;
                                                                {currentCustomer.province_name} -&nbsp;
                                                                {currentCustomer.district_name} -&nbsp;
                                                                {currentCustomer.wards_name} -&nbsp;
                                                                {ReactHtmlParser(currentCustomer.address)}
                                                            </Descriptions.Item>
                                                            <Descriptions.Item span={24} className='mt-2'>
                                                                <div className='mb-1'><b>Media:</b></div>
                                                                <Image.PreviewGroup>
                                                                    <Space wrap>
                                                                        {
                                                                            currentCustomer?.customer_media.map(item => {
                                                                                return (
                                                                                    <Image
                                                                                        //width={50}
                                                                                        height={50}
                                                                                        src={item.source}
                                                                                        style={{ border: '1px solid #dedede' }}
                                                                                    />
                                                                                )
                                                                            })
                                                                        }
                                                                    </Space>
                                                                </Image.PreviewGroup>
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                    ) : (
                                                        <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center' }} key={1}>
                                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                        </Row>
                                                    )
                                                }
                                                <Divider orientationMargin={0} orientation="left" className="no-margin">Thông tin trên facebook</Divider>
                                                <Descriptions column={24} size="small">
                                                    <Descriptions.Item span={24}>
                                                        <UserOutlined /> <b>Tên:</b> &nbsp;
                                                        {
                                                            sender != undefined ? (
                                                                <Paragraph style={{ margin: 0, display: 'inline-block' }} copyable>{sender.sender_name}</Paragraph>
                                                            ) : null
                                                        }
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <WomanOutlined /> <b>Giới tính:</b> &nbsp; {sender != undefined ? (<>{sender.sender_gender}</>) : null}
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <PhoneOutlined /> <b>Điện thoại:</b>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <MailOutlined /> <b>Email:</b>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <GiftOutlined /> <b>Ngày sinh:</b>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <HomeOutlined /> <b>Địa chỉ:</b>
                                                    </Descriptions.Item>
                                                    {/* <Descriptions.Item span={24}>
                                                        <EnvironmentOutlined /> <b>Sống ở:</b>
                                                    </Descriptions.Item>
                                                    <Descriptions.Item span={24}>
                                                        <EnvironmentOutlined /> <b>Đến từ:</b>
                                                    </Descriptions.Item> */}
                                                </Descriptions>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane key="add_customer" tab={<span><UserAddOutlined />Thêm khách hàng</span>}>
                                                <div className='wrap-add-customer'>
                                                    <Spin tip="Loading..." spinning={isLoading}>
                                                        <Form layout='vertical' size='small'>
                                                            <Divider orientation="left" orientationMargin="0" className='mt-0 mb-1'>
                                                                Thông tin khách hàng
                                                            </Divider>
                                                            <Row gutter={[5, 5]}>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('full_name', {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Vui lòng điền!',
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.full_name : sender != undefined ? sender.sender_name : null
                                                                        })(<Input placeholder='Tên khách hàng' />)}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("gender", {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: "Vui lòng điền!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.gender : sender != undefined ? sender.sender_gender : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={customerGender || []}
                                                                                placeholder="Giới tính"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("phone", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng điền!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.phone : null
                                                                        })(
                                                                            <Input placeholder='Số điện thoại' />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('branch_management_id', {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Vui lòng điền',
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.branch_management_id : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={branchActiveList || []}
                                                                                optionValue="id"
                                                                                optionLabel="name"
                                                                                placeholder="Chọn cơ sở"
                                                                                className="w-100"
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item >
                                                                        {getFieldDecorator("province_code", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.province_code : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={list_provinces || []}
                                                                                optionValue="code"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Tỉnh/Thành phố"
                                                                                style={{ width: '100%' }}
                                                                                onChange={(code) => this.loadDistricts(code)}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item >
                                                                        {getFieldDecorator("district_code", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.district_code : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={districts || []}
                                                                                optionValue="code"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Quận/Huyện"
                                                                                style={{ width: '100%' }}
                                                                                onChange={(code) => this.loadWards(code)}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                                                Dịch vụ
                                                            </Divider>
                                                            <Row gutter={[5, 5]}>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("interested_service_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined && currentCustomer.services_interested ? currentCustomer.services_interested?.service_id : null
                                                                        })(
                                                                            <TreeSelect
                                                                                showSearch
                                                                                style={{ width: '100%' }}
                                                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                                                treeData={service}
                                                                                treeNodeFilterProp='title'
                                                                                allowClear
                                                                                placeholder="Dịch vụ quan tâm"
                                                                                treeDefaultExpandAll
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("selected_service_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined && currentCustomer.services_selected ? currentCustomer.services_selected?.service_id : null
                                                                        })(
                                                                            <TreeSelect
                                                                                showSearch
                                                                                style={{ width: '100%' }}
                                                                                dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                                                                treeData={service}
                                                                                treeNodeFilterProp='title'
                                                                                allowClear
                                                                                placeholder="Dịch vụ chốt"
                                                                                treeDefaultExpandAll
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                                                Lịch hẹn
                                                            </Divider>
                                                            <Row gutter={[5, 5]}>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label='Tên lịch hẹn'>
                                                                        {getFieldDecorator("appointment_schedule_name", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer && currentCustomer.appointment_schedule ? currentCustomer.appointment_schedule?.name : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={CustomerScheduleType}
                                                                                showSearch
                                                                                placeholder="Chọn một bản ghi"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} md={12}>
                                                                    <Form.Item label="Thời gian">
                                                                        {getFieldDecorator('appointment_schedule_time', {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: 'Vui lòng điền!',
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer && currentCustomer.appointment_schedule ? isValidDateForm(currentCustomer.appointment_schedule?.time, false) : null
                                                                        })(
                                                                            <DatePicker
                                                                                style={{ width: '100%' }}
                                                                                showTime={{ format: 'HH:mm' }}
                                                                                format="DD/MM/YYYY HH:mm"
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                                                Tương tác
                                                            </Divider>
                                                            <Row gutter={[5, 5]}>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("customer_status_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.customer_status_id : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={customerStatusActiveList || []}
                                                                                optionValue="id"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Trạng thái"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator("fb_Interactive_type_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.fb_Interactive_type_id : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={customerInteractiveTypeActiveList || []}
                                                                                optionValue="id"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Loại tương tác"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item >
                                                                        {getFieldDecorator("fb_Interactive_status_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.fb_Interactive_status_id : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={customerInteractiveStatusActiveList || []}
                                                                                optionValue="id"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Trạng thái tương tác"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                                    <Form.Item >
                                                                        {getFieldDecorator("customer_source_id", {
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: "Vui lòng chọn!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer != undefined ? currentCustomer.customer_source_id : null
                                                                        })(
                                                                            <BaseSelect
                                                                                options={customer_source_active_list || []}
                                                                                optionValue="id"
                                                                                optionLabel="name"
                                                                                showSearch
                                                                                placeholder="Nguồn khách hàng"
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Divider orientation="left" orientationMargin="0" className='mb-1'>
                                                                Ghi chú
                                                            </Divider>
                                                            <Row gutter={[5, 5]}>
                                                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                                    <Form.Item>
                                                                        {getFieldDecorator('note', {
                                                                            rules: [
                                                                                {
                                                                                    required: false,
                                                                                    message: "Vui lòng điền!",
                                                                                },
                                                                            ],
                                                                            initialValue: currentCustomer ? _newLine(currentCustomer.note) : ""
                                                                        })(
                                                                            <Input.TextArea rows={4} placeholder='Nội dung tư vấn' />
                                                                        )}
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                currentCustomer ? (
                                                                    <>
                                                                        {
                                                                            checkPermission('customer', 'update_from_chat') ? (
                                                                                <Row gutter={16} className="mb-2" justify="end" style={{ margin: 0 }}>
                                                                                    <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submitCustomer(e)}>Cập nhật</Button>
                                                                                </Row>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            checkPermission('customer', 'create_from_chat') ? (
                                                                                <Row gutter={16} className="mb-2" justify="end" style={{ margin: 0 }}>
                                                                                    <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submitCustomer(e)}>Lưu</Button>
                                                                                </Row>
                                                                            ) : null
                                                                        }
                                                                    </>
                                                                )

                                                            }
                                                        </Form>
                                                    </Spin>
                                                </div>
                                            </Tabs.TabPane>
                                        </Tabs>
                                    </div>
                                </Card>
                            </Col>
                        ) : null
                    }
                </Row>
                <audio ref={this.playerSentSoundRef} src={messageSentSound} />
                <audio ref={this.playerReceivedSoundRef} src={messageReceivedSound} />
                <ChatSpecific
                    visible={isOpenChat}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenChat(false)}
                />

                <Drawer
                    visible={isOpenFilter}
                    onClose={() => this.setState({ isOpenFilter: false })}
                    maskClosable={false}
                    width={isMobile ? '100%' : '40%'}
                    footer={null}
                >
                    <>
                        {
                            list_page_active && list_page_active.length ? (
                                <>
                                    {
                                        list_page_active.map(page => (
                                            <div className='list-page-filter'>
                                                <CheckableTag
                                                    key={page.page_id}
                                                    checked={selectedFilterPage.indexOf(page.page_id) > -1}
                                                    onChange={checked => this.onCheckTagFilterPage(page.page_id, checked)}
                                                    style={{ fontWeight: '500' }}
                                                >
                                                    <Avatar icon={<UserOutlined />} style={{ marginBottom: '-20px' }} size={40} src={page.page_picture} /> {page.page_name}
                                                    <div style={{ marginLeft: '45px' }}><strong>ID: </strong>{page.page_id}</div>
                                                </CheckableTag>
                                            </div>
                                        ))
                                    }
                                </>
                            ) : null
                        }
                    </>
                </Drawer>
            </Card >
        )
    }
}


function mapStateToProps(state) {
    //console.log(state.PageManagerReducer)
    return {
        list_conversations: state.ConversationsReducer.list_conversations.convertsations.convertsations,
        converstaions_pagination: state.ConversationsReducer.list_conversations.convertsations.pagination,
        list_current_page: state.ConversationsReducer.list_conversations.list_current_page,
        customerClassActiveList: state.CustomerClassReducer.customerClassActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        service_active_list: state.ServiceReducer.service_active_list,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        list_page_active: state.PageManagerReducer.list_page_active,
        branchActiveList: state.BranchManagementReducer.branchActiveList,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListConversation: (filter) => dispatch(getListConversation(filter)),
        getListMessages: (filter) => dispatch(getListMessages(filter)),
        updateHasRead: (message_ids, has_read) => dispatch(updateHasRead(message_ids, has_read)),
        createCustomerFromChat: (data) => dispatch(createCustomerFromChat(data)),
        updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerByFacebookName: (filter) => dispatch(getCustomerByFacebookName(filter)),
        getListPageActive: (filter) => dispatch(getListPageActive(filter)),
        updateCustomerFromChat: (data) => dispatch(updateCustomerFromChat(data)),
        getBranchActive: () => dispatch(getBranchActive()),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Conversations' })(Conversations));
