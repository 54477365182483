import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Form, Button, Input, Checkbox, Card, Row } from 'antd';
// actions
import { login } from '../../redux/actions/AuthActions';
import { getConfig } from '../../redux/actions/ConfigActions';
import { getCustomerClassActive } from '../../redux/actions/CustomerClassActions';
import { getCustomerStatusActive } from '../../redux/actions/CustomerStatusActions';
import { getServiceActive } from '../../redux/actions/ServiceActions';
import { getCustomerInteractiveStatusActive } from '../../redux/actions/CustomerInteractiveStatusActions';
import { getCustomerInteractiveTypeActive } from '../../redux/actions/CustomerInteractiveTypeActions';
import { addDeviceToken } from '../../redux/actions/NotificationsActions';

import firebaseService from '../../utils/FirebaseService';
import logo from '../../assets/img/logo.png';

class Login extends Component {
    onFinish = async (values) => {
        //console.log('Success:', values);
        await this.props.login(values);
        await this.props.getConfig();
        await this.props.getCustomerClassActive();
        await this.props.getCustomerStatusActive();
        await this.props.getServiceActive();
        await this.props.getCustomerInteractiveStatusActive();
        await this.props.getCustomerInteractiveTypeActive();

        // Firebase lấy token
        firebaseService.fetchToken(this.setCurrentTokenCallback);

    };

    setCurrentTokenCallback = (token) => {
        console.log('Current token:', token);
        this.props.addDeviceToken({ device_token: token });
    };

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    render() {
        var { authUser } = this.props;

        if (authUser) return <Redirect to='/' />;

        return (
            <Row justify='center'>
                <Card className="login-card">
                    <div className="text-center">
                        <img src={logo} className="login-logo" alt="Logo" />
                    </div>
                    <Form
                        className="mt-4"
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Form.Item
                            name="user_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền username!',
                                },
                            ]}
                        >
                            <Input placeholder="Username" />
                        </Form.Item>

                        <Form.Item
                            name="user_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng điền mật khẩu!',
                                },
                            ]}
                        >
                            <Input.Password placeholder="Mật khẩu" />
                        </Form.Item>

                        <Row justify="space-between">
                            <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>Nhớ mật khẩu</Checkbox>
                            </Form.Item>
                            <Link to='/reset-password'>Quên mật khẩu?</Link>
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="w-100">
                                Đăng nhập
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Row>
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (data) => dispatch(login(data)),
        getConfig: () => dispatch(getConfig()),
        getCustomerClassActive: (filter) => dispatch(getCustomerClassActive(filter)),
        getCustomerStatusActive: (filter) => dispatch(getCustomerStatusActive(filter)),
        getServiceActive: (filter) => dispatch(getServiceActive(filter)),
        getCustomerInteractiveStatusActive: (filter) => dispatch(getCustomerInteractiveStatusActive(filter)),
        getCustomerInteractiveTypeActive: (filter) => dispatch(getCustomerInteractiveTypeActive(filter)),
        addDeviceToken: (data) => dispatch(addDeviceToken(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);