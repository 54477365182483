import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, TreeSelect, InputNumber, DatePicker, Table, Popconfirm } from 'antd';
import { DeleteTwoTone, UserOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import ServicesProvidedExaminationVisit from './ServicesProvidedExaminationVisit';
import ServicesProvidedConsultantDirectly from './ServicesProvidedConsultantDirectly';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications';
import ListCurrentImage from '../../components/ListCurrentImage';
// actions
import { createServicesProvided, updateServicesProvided } from '../../redux/actions/ServicesProvidedActions';
import { getSpecificService } from '../../redux/actions/ServiceActions';
import { makeTree, customDataImageUpload, isValidDateForm, _newLine } from '../../utils/helpers';
import NumberFormat from 'react-number-format';
import { checkPermission } from '../../utils/permission';

class ServicesProvidedSelectedForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            provided_examination_visit: [],
            provided_consultant_directly: [],
            condition_before: [],
            condition_after: [],
            isResetUploadConditionBefore: true,
            isResetUploadConditionAfter: true,
            currentConditionBefore: [],
            currentConditionAfter: [],
            items: [],
            initialValueDate: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.condition_before && Array.isArray(nextProps?.currentItem?.condition_before)) {
                this.setState({ currentConditionBefore: nextProps?.currentItem?.condition_before })
            } else {
                this.setState({ currentConditionBefore: [] })
            }
            if (nextProps?.currentItem?.condition_after && Array.isArray(nextProps?.currentItem?.condition_after)) {
                this.setState({ currentConditionAfter: nextProps?.currentItem?.condition_after })
            } else {
                this.setState({ currentConditionAfter: [] })
            }
            if (nextProps?.currentItem?.services && Array.isArray(nextProps?.currentItem?.services)) {
                this.renderNewItem(nextProps?.currentItem?.services)
            } else {
                this.setState({ items: [] })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.condition_before;
            delete values.condition_after;
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { provided_examination_visit,
                        provided_consultant_directly,
                        condition_before,
                        condition_after,
                        currentConditionBefore,
                        currentConditionAfter,
                        items } = this.state;
                    const { currentCustomer } = this.props;

                    const services_selected = {
                        services: items,
                        provided_examination_visit: provided_examination_visit,
                        provided_consultant_directly: provided_consultant_directly,
                        condition_before: customDataImageUpload(condition_before, currentConditionBefore),
                        condition_after: customDataImageUpload(condition_after, currentConditionAfter),
                        selected_at: values.selected_at ? values.selected_at.format("YYYY-MM-DD") : null,
                        total_amount: values.total_amount,
                        require_consultant_directly: checkPermission('services_provided', 'require_input_consultant_directly'),
                        require_examination_visit: checkPermission('services_provided', 'require_input_examination_visit')
                    }

                    values.services_selected = services_selected;

                    delete values.price;
                    delete values.note;
                    delete values.current_status;
                    delete values.selected_service_id;
                    delete values.selected_at;
                    delete values.total_amount;

                    if (this.props.currentItem) {
                        await this.props.updateServicesProvided(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        values.customer_id = currentCustomer ? currentCustomer?.id : values.customer_id;
                        await this.props.createServicesProvided(values);
                        this.onClose();
                    }
                    console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
            provided_examination_visit: [],
            provided_consultant_directly: [],
            condition_before: [],
            condition_after: [],
            isResetUploadConditionBefore: true,
            isResetUploadConditionAfter: true,
            items: []
        });
    }

    getProvidedExaminationVisitItems = data => {
        this.setState({ provided_examination_visit: data })
    }

    getProvidedConsultantItems = data => {
        this.setState({ provided_consultant_directly: data })
    }

    onChangeService = (id) => {
        this.setState({ isloading: true });
        this.props.getSpecificService(id).then((item) => {
            let new_items = {
                id: item.id,
                name: item.name,
                regular_price: item.price,
                sale_price: item.price,
                quantity: 1,
                amount: item.price * 1,
                note: null
            };

            // Kiểm tra nếu bản ghi nếu là cập nhật
            let isUpdating = this.props.currentItem;

            this.setState({
                items: isUpdating
                    ? [new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
                    : [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                isloading: false
            }, () => {
                this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
            });
        }).catch(err => {
            this.setState({ isloading: false });
        });
        this.calculateTotalAmount(); // Tính toán lại total_amount và debt
    };


    calculateTotalAmount = () => {
        let total_amount = 0;
        this.state.items.forEach(item => {
            total_amount += item.sale_price * item.quantity;
        });
        this.props.form.setFieldsValue({
            total_amount: total_amount
        });
    };

    // async onChangeItem(name, value, itemId) {
    //     let items = [...this.state.items];
    //     let index = items.findIndex(item => item.id == itemId);
    //     let currentItem = items[index];
    //     currentItem[name] = value;
    //     items[index] = currentItem;
    //     this.renderNewItem(items)
    // }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];

        // Cập nhật giá trị cho thuộc tính đang thay đổi
        currentItem[name] = value;

        // Nếu thay đổi quantity hoặc sale_price thì tính lại amount
        if (name === 'quantity' || name === 'sale_price') {
            currentItem.amount = this.calculateAmount(currentItem);
            this.calculateTotalAmount();
        }

        // Cập nhật lại items và render lại
        items[index] = currentItem;
        this.renderNewItem(items);
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            return {
                // id: item.id,
                // name: item.name,
                // regular_price: item.regular_price,
                // sale_price: item.sale_price,
                // quantity: item.quantity,
                // amount: item.quantity * item.sale_price,
                ...item,
                note: _newLine(item.note)
            }
        })
        //console.log("new_items", new_items);
        // this.setState({
        //     items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        // }, () => {
        //     this.calculateTotalAmount(); // Gọi sau khi setState để đảm bảo rằng state đã được cập nhật
        // });

        // Cập nhật state với các giá trị mới
        this.setState({ items: new_items }, () => {
            // Tính toán và cập nhật các giá trị cần thiết
            let total_amount = new_items.reduce((total, item) => total + item.amount, 0);
            this.props.form.setFieldsValue({
                total_amount: total_amount
            });
        });
    }

    calculateAmount(item) {
        return item.quantity * item.sale_price;
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.id != id;
        });
        this.renderNewItem(items)
    }

    onChangeConditionBefore = (data) => {
        this.setState({ isResetUploadConditionBefore: false, condition_before: data });
        NotificationManager.success("Condition before complete.");
    }

    onChangeConditionAfter = (data) => {
        this.setState({ isResetUploadConditionAfter: false, condition_after: data });
        NotificationManager.success("Condition after complete.");
    }

    getImages(data) {
        this.setState({ currentImages: data })
    }

    handleDateChange = (date) => {
        if (date) {
            this.setState({
                initialValueDate: date,
            });
        } else {
            this.setState({
                initialValueDate: null,
            });
        }
    };


    render() {
        var { isLoading,
            isResetUploadConditionBefore,
            isResetUploadConditionAfter,
            currentConditionBefore,
            currentConditionAfter,
            items, initialValueDate
        } = this.state;
        var {
            visible,
            currentItem,
            service_active_list,
            currentCustomer
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);

        var serviceColumns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Dịch vụ",
                dataIndex: "name"
            },
            {
                title: "Ghi chú",
                dataIndex: "note",
                width: "200px",
                render: (text, record) => {
                    return (
                        <Input.TextArea
                            value={text}
                            name="note"
                            style={{ height: 16, marginBottom: 0 }}
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: '80px',
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="quantity"
                            min={1}
                            className='w-100'
                            onChange={(value) => {
                                // Kiểm tra giá trị và thay thế nếu cần thiết
                                const validValue = value == null || value <= 0 ? 1 : value;
                                this.onChangeItem('quantity', validValue, record.id);
                            }}
                        />
                    )
                }
            },
            {
                title: "Giá gốc",
                dataIndex: "regular_price",
                width: "110px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Giá bán",
                dataIndex: "sale_price",
                width: "120px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="sale_price"
                            min={0}
                            className='w-100'
                            onChange={(value) => this.onChangeItem('sale_price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                render: (text, record) => {
                    // return (
                    //     <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    // )
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="amount"
                            min={0}
                            className='w-100'
                            onChange={(value) => this.onChangeItem('amount', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa bản ghi này?"
                            onConfirm={() => this.onDelete(record.id)}
                        >
                            <DeleteTwoTone twoToneColor="#fe0000" />
                        </Popconfirm>
                    )
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.sale_price;
        });

        return (
            <Drawer
                visible={visible}
                title={currentItem ? 'Sửa dịch vụ chốt' : `Thêm dịch vụ chốt`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '70%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? 'Cập nhật' : "Thêm mới"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{`${currentCustomer?.full_name} - ${currentCustomer?.code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={14}>
                                <Form.Item label='Dịch vụ'>
                                    {getFieldDecorator("selected_service_id", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={service}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn một bản ghi"
                                            treeDefaultExpandAll
                                            onChange={this.onChangeService}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={10}>
                                <Form.Item label="Ngày chốt">
                                    {getFieldDecorator('selected_at', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? isValidDateForm(currentItem.selected_at, false) : null
                                    })(
                                        <DatePicker
                                            className='w-100'
                                            format="DD/MM/YYYY"
                                            onChange={this.handleDateChange}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24} className='mb-3'>
                                <Table
                                    className='mt-2'
                                    size='small'
                                    rowKey='key'
                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                    tableLayout="auto"
                                    columns={serviceColumns}
                                    dataSource={items || []}
                                    pagination={false}
                                    scroll={{
                                        x: 'max-content'
                                    }}
                                    footer={() => {
                                        return (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: '600' }}>
                                                <div style={{ marginRight: '10px' }}>Tổng giá trị:</div>
                                                <div>
                                                    {getFieldDecorator('total_amount', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng điền!',
                                                            },
                                                        ],
                                                        initialValue: total_amount
                                                    })(
                                                        <InputNumber
                                                            disabled
                                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            className="w-100 custom-input-number"
                                                            step={100000}
                                                            addonAfter="đ"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <ServicesProvidedConsultantDirectly currentDate={initialValueDate} currentServicesProvidedConsultantDirectly={currentItem ? currentItem.consultant_directly : null} getItems={(data) => this.getProvidedConsultantItems(data)} />
                            </Col>
                        </Row>
                        <Row gutter={16} className='mt-4'>
                            <Col xs={24} md={24}>
                                <ServicesProvidedExaminationVisit currentDate={initialValueDate} currentServicesProvidedExaminationVisit={currentItem ? currentItem.examination_visit : null} getItems={(data) => this.getProvidedExaminationVisitItems(data)} />
                            </Col>
                        </Row>
                        <Row gutter={16} className='mt-4'>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ảnh trước khi dùng dịch vụ" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    <ListCurrentImage currentImages={currentConditionBefore} getData={(data) => this.getImages('currentConditionBefore', data)} />
                                    {getFieldDecorator('condition_before', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            uploadText="Chọn ảnh"
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeConditionBefore}
                                            isResetUpload={isResetUploadConditionBefore}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={12}>
                                <Form.Item label="Ảnh sau khi dùng dịch vụ" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    <ListCurrentImage currentImages={currentConditionAfter} getData={(data) => this.getImages('currentConditionAfter', data)} />
                                    {getFieldDecorator('condition_after', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            uploadText="Chọn ảnh"
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeConditionAfter}
                                            isResetUpload={isResetUploadConditionAfter}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_active_list: state.ServiceReducer.service_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createServicesProvided: (data) => dispatch(createServicesProvided(data)),
        updateServicesProvided: (id, data) => dispatch(updateServicesProvided(id, data)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ServicesProvidedSelectedForm' })(ServicesProvidedSelectedForm));
