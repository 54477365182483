import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PhoneOutlined } from '@ant-design/icons';
import { Popover, Button, } from 'antd';
import { Form } from '@ant-design/compatible';
import { StringeeClient, StringeeCall } from 'stringee-chat-js-sdk';
import { checkPermission } from '../../src/utils/permission';
import avatar from '../assets/icons/avatar.png'
import icon_dropdown from '../assets/icons/icon-dropdown.svg'
import icon_minimize from '../assets/icons/icon-minimize.svg'
import icon_close from '../assets/icons/icon-close.svg'
import icon_phone from '../assets/icons/icon-phone.svg';
import { NotificationManager } from 'react-notifications';
import { getAccessToken } from '../redux/actions/StringeeActions';

var stringeeClient;
var call;
var authenticatedWithUserId = '';
var fromNumber;
var callTo;
var call_Status = '';

function replacePhoneNumber(s) {
    if (!s) return;
    while (s.substr(0, 1) == '0' && s.length > 1) {
        s = s.substr(1, 9999);
    }
    return '84' + s;
}

class Stringee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            digit: [],
            callStatus: '',
            page_incomming_call: 'display-none',
            page_diapad: '',
            hangupBtn: 'display-none',
            callBtn: '',
            wrap_status_time: '',
            wrap_agent_time: 'display-none',
            callToAlias: '',
            openPopover: false
        }

        this.textInput = React.createRef();
        this.audioRef = React.createRef()
    }

    componentDidMount() {
        this.handleLogin()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("nextProps: ", nextProps)
        if (nextProps.callToNumber) {
            this.setState({ callToAlias: nextProps.callToAlias, openPopover: nextProps.openPopover })
            this.onMakeCall(nextProps.callToNumber);
        }
    }

    // componentDidUpdate(prevProps) {
    //     console.log("prevProps: ", prevProps)
    //     if (prevProps.callToNumber != this.props.callToNumber) {
    //         this.setState({ callToAlias: prevProps.callToAlias, openPopover: prevProps.openPopover })
    //         this.onMakeCall(prevProps.callToNumber);
    //     }
    // }

    handleLogin() {
        this.props.getAccessToken().then(res => {
            if (res) {
                var accessToken = res.data.access_token;
                console.log('accessToken...: ' + accessToken);
                stringeeClient = new StringeeClient();
                this.settingClientEvents(stringeeClient);
                stringeeClient.connect(accessToken);
            }
        })
    }

    disabledReloadPage() {
        // Chặn không cho load lại trang khi đang thực hiện cuộc gọi
        document.addEventListener('keydown', (e) => {
            e = e || window.event;
            if (e.keyCode == 116) {
                e.preventDefault();
            }
        });
        window.onbeforeunload = function () {
            return "Đang thực hiện cuộc gọi! Vui lòng không tải lại trang.";
        }
    }

    onMakeCall(callToNumber = null) {
        //console.log("call_Status: ", call_Status)
        //this.disabledReloadPage();
        if (call_Status != '' && call_Status != 'Ended' && call_Status != 'Busy here') {
            NotificationManager.error("Vui lòng kết thúc cuộc gọi hiện tại!");
            return;
        }
        if (callToNumber) {
            if (this.textInput.current) {
                this.textInput.current.value = ''
            }
            callTo = replacePhoneNumber(callToNumber);
        } else {
            this.setState({ callToAlias: this.textInput.current.value })
            callTo = replacePhoneNumber(this.textInput.current.value);
        }

        if (callTo.length === 0) {
            return;
        }
        console.log("callTo: ", callTo)

        //fromNumber = '842471008865';
        fromNumber = '842873030096';
        if (fromNumber.length === 0) {
            fromNumber = authenticatedWithUserId;
        }

        call = new StringeeCall(stringeeClient, fromNumber, callTo, false);
        console.log("StringeeCall...: ", call)

        this.settingCallEvents(call);

        call.makeCall((res) => {
            console.log('make call callback: ' + JSON.stringify(res));
            if (res.r !== 0) {
                call_Status = res.message;
                this.setState({ callStatus: res.message })
            }
        });
    }

    settingClientEvents(client) {
        client.on('connect', function () {
            console.log('connected to StringeeServer');
        });

        client.on('authen', function (res) {
            console.log('on authen: ', res);
            authenticatedWithUserId = res.userId;
        });

        client.on('disconnect', function () {
            console.log('disconnected');
        });

        client.on('incomingcall', function (incomingcall) {
            //call = incomingcall;
            this.settingCallEvents(incomingcall);
            console.log('incomingcall: ', incomingcall);
        });

        client.on('requestnewtoken', function () {
            console.log('request new token; please get new access_token from YourServer and call client.connect(new_access_token)');
            //please get new access_token from YourServer and call: 
            //client.connect(new_access_token);
        });

        client.on('otherdeviceauthen', function (data) {
            console.log('otherdeviceauthen: ', data);
        });
    }

    settingCallEvents(call1) {
        this.setState({
            page_incomming_call: '',
            page_diapad: 'display-none',
            hangupBtn: '',
            callBtn: 'display-none'
        })

        call1.on('error', function (info) {
            console.log('on error: ' + JSON.stringify(info));
        });

        call1.on('addlocalstream', function (stream) {
            console.log('on addlocalstream', stream);
        });

        call1.on('addremotestream', (stream) => {
            console.log('on addremotestream', stream);
            // reset srcObject to work around minor bugs in Chrome and Edge.
            this.audioRef.current.srcObject = null;
            this.audioRef.current.srcObject = stream;
            this.audioRef.current.play();
        });

        call1.on('signalingstate', (state) => {
            console.log('signalingstate', state);

            if (state.code == 6) {//call ended
                this.callStopped();
            }

            if (state.code == 5) {//busy here
                this.callStopped();
            }

            var reason = state.reason;
            console.log("callStatus: ", reason)
            call_Status = reason;
            this.setState({ callStatus: reason })
        });

        call1.on('mediastate', function (state) {
            console.log('mediastate ', state);
        });

        call1.on('info', function (info) {
            console.log('on info', info);
        });

        call1.on('otherdevice', function (data) {
            console.log('on otherdevice:' + JSON.stringify(data));
        });
    }

    onHangupCall() {
        this.audioRef.current.srcObject = null;
        this.callStopped();
        call.hangup(function (res) {
            console.log('hangup res', res);
        });
    }

    callStopped() {
        call_Status = 'Call ended';
        this.setState({
            callStatus: 'Call ended',
            wrap_agent_time: '',
            wrap_status_time: 'display-none'
        })
        setTimeout(() => {
            this.setState({
                page_incomming_call: 'display-none',
                page_diapad: '',
                wrap_agent_time: 'display-none',
                wrap_status_time: '',
                hangupBtn: 'display-none',
                callBtn: '',
                callToAlias: ''
            })
        }, 2000);
    }

    appendDigit(value) {
        if (this.state.digit.length < 11) {
            this.setState({
                digit: [...this.state.digit, value]
            },
                () => {
                    this.textInput.current.value = this.state.digit.join("")
                });
        }
    }

    clearValue() {
        this.setState({ digit: [] })
        this.textInput.current.value = null
    }

    isOpenPopover() {
        this.setState({ open: !this.state.open, openPopover: false })
    }

    render() {
        const { } = this.props;
        const { callStatus,
            page_incomming_call,
            page_diapad, hangupBtn,
            callBtn, wrap_agent_time, wrap_status_time,
            callToAlias, callToNumber, openPopover
        } = this.state;
        return (
            <>
                <Popover
                    className='popover-stringee'
                    overlayClassName="popover-overlay-stringee"
                    placement="bottomRight"
                    trigger="click"
                    open={openPopover ? openPopover : this.state.open}
                    content={
                        <div className='stringee_clound'>
                            <div id="stringee_clound" className="overflow-hidden">
                                <div id="app">
                                    <section className="wrap-top-bar height-40 position-absolute top-0 width-100-percent">
                                        <div className="top-bar-status color-green">{authenticatedWithUserId ? authenticatedWithUserId : <span style={{ color: 'red' }}>Chưa kết nối</span>}</div>
                                        <div className="wrap-action-top-right float-right mr position-absolute right-15 top-5">
                                            <button onClick={() => this.isOpenPopover()} id="btnMinimize" className="btn-minimize border-none bg-transparent" title="Minimize">
                                                <img src={icon_minimize} />
                                            </button>
                                        </div>
                                    </section>
                                    <section id="page-incomming-call" className={`page ${page_incomming_call}`}>
                                        <div className="incomming-call-info box-shadow1 border-radius-18 bg-gradient-purple overflow-hidden mb-20">
                                            <div className="wrap-info-text bg-white pt-20 pb-20">
                                                <section className="wrap-status p-l-r-20 top-0 position-relative display-flex ">
                                                    <div className="wrap-status-text line-height-30">
                                                        <span className="status-text">{callStatus}</span>
                                                        <div className="line-red-short" />
                                                    </div>
                                                    <div className={`wrap-agent-time line-height-30 position-absolute right-0 ${wrap_agent_time}`}>
                                                        <span className="agent-time"></span>
                                                    </div>
                                                    <div className={`wrap-status-time line-height-30 position-absolute right-0 ${wrap_status_time}`}>
                                                        <span className="status-timee"></span>
                                                    </div>
                                                </section>
                                                <section className="wrap-info p-20">
                                                    <div className="info-name pb-10">
                                                        <a className="contact-info-name" href="javascript:void(0)">{callToAlias}</a>
                                                    </div>
                                                    <div className="wrap-location">
                                                        <span className="location-via" data-translate="via">Thông qua&nbsp;</span>
                                                        <span className="location-text" data-translate="location">{fromNumber}</span>
                                                    </div>
                                                </section>
                                            </div>
                                            <section className="wrap-background bg-gradient-purple height-350 width-100-percent">
                                                <div className="wrap-avatar-round text-center">
                                                    <img src={avatar} className="mt-50" />
                                                </div>
                                                <div className="callstart-time">
                                                    <div className="location-queuetime" />
                                                    <div className="location-queue"> </div>
                                                </div>
                                            </section>
                                        </div>
                                    </section>
                                    <section id="page-diapad" className={`page ${page_diapad}`}>
                                        <div className="wrap-typing-number pl-15 pr-15 mt-10 mb-10 display-flex justify-content-space-between">
                                            <input ref={this.textInput} type="text" placeholder="84966050926" className="font-size-24 color-black border-none" style={{ width: '190px' }} />
                                            <button className="btn-close border-none bg-transparent" onClick={() => this.clearValue()}>
                                                <img src={icon_close} />
                                            </button>
                                        </div>
                                        <div className="wrap-diapad mb-10">
                                            <div className="diapad-row">
                                                <button className="diapad-key" id="diapad-key-1" onClick={() => this.appendDigit(1)}>
                                                    <span className="diapad-key-number">1</span>
                                                    <span className="diapad-key-text" />
                                                </button>
                                                <button className="diapad-key" id="diapad-key-2" onClick={() => this.appendDigit(2)}>
                                                    <span className="diapad-key-number">2</span>
                                                    <span className="diapad-key-text">ABC</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-3" onClick={() => this.appendDigit(3)}>
                                                    <span className="diapad-key-number">3</span>
                                                    <span className="diapad-key-text">DEF</span>
                                                </button>
                                            </div>
                                            <div className="diapad-row">
                                                <button className="diapad-key" id="diapad-key-4" onClick={() => this.appendDigit(4)}>
                                                    <span className="diapad-key-number">4</span>
                                                    <span className="diapad-key-text">GHI</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-5" onClick={() => this.appendDigit(5)}>
                                                    <span className="diapad-key-number">5</span>
                                                    <span className="diapad-key-text">JKL</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-6" onClick={() => this.appendDigit(6)}>
                                                    <span className="diapad-key-number">6</span>
                                                    <span className="diapad-key-text">MNO</span>
                                                </button>
                                            </div>
                                            <div className="diapad-row">
                                                <button className="diapad-key" id="diapad-key-7" onClick={() => this.appendDigit(7)}>
                                                    <span className="diapad-key-number">7</span>
                                                    <span className="diapad-key-text">PQRS</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-8" onClick={() => this.appendDigit(8)}>
                                                    <span className="diapad-key-number">8</span>
                                                    <span className="diapad-key-text">TUV</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-9" onClick={() => this.appendDigit(9)}>
                                                    <span className="diapad-key-number">9</span>
                                                    <span className="diapad-key-text">WXYZ</span>
                                                </button>
                                            </div>
                                            <div className="diapad-row">
                                                <button className="diapad-key" id="diapad-key-star" onClick={() => this.appendDigit('*')}>
                                                    <span className="diapad-key-number">*</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-0" onClick={() => this.appendDigit(0)}>
                                                    <span className="diapad-key-number">0</span>
                                                </button>
                                                <button className="diapad-key" id="diapad-key-sharp" onClick={() => this.appendDigit('#')}>
                                                    <span className="diapad-key-number">#</span>
                                                    <span className="diapad-key-text" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="wrap-call-using-dropdown position-relative display-none">
                                            <div id="list-from-numbers" className="call-using-dropdown box-shadow3 border-radius-8 bg-white">
                                                <div className="call-using-dropdown-item cursor-pointer p-15 pt-10 pb-10">
                                                    <div>
                                                        <span className="call-using-text-name display-block">84916019696</span>
                                                        <span className="call-using-text-phone display-block">+84916019696</span>
                                                    </div>
                                                </div>
                                                <div className="call-using-dropdown-item cursor-pointer p-15 pt-10 pb-10">
                                                    <div>
                                                        <span className="call-using-text-name display-block">84978009780</span>
                                                        <span className="call-using-text-phone display-block">+84978009780</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="icon-dropdown right-40" />
                                        </div>
                                        <div className="wrap-call-using pl-20 pr-20 mb-10 position-relative cursor-pointer">
                                            <div className="call-using-text" data-translate="using_number">Sử dụng số</div>
                                            <div className="call-using-select pt-10 pb-10 pr-15 pl-15 display-flex justify-content-space-between">
                                                <div>
                                                    <span id="from-number-callout-alias" className="call-using-text-name display-block">84916019696</span>
                                                    <span id="from-number-callout" className="call-using-text-phone display-block">+84916019696</span>
                                                </div>
                                                <img src={icon_dropdown} />
                                            </div>
                                        </div>
                                    </section>
                                    <section style={{ padding: '5px 0' }} className="wrap-toolbar-bottom position-absolute bottom-0 height-100 width-100-percent box-shadow2 z-index-100 bg-white">
                                        <div className="wrap-toolbar text-center display-flex justify-content-space-evenly">
                                            <button onClick={() => this.onMakeCall()} className={`btn-action-incomming btn-round btn-size-55 display-table-cell border-none mt-15-negative btn-green ${callBtn}`}>
                                                <img src={icon_phone} className="icon-gray" />
                                            </button>
                                            <button onClick={() => this.onHangupCall()} className={`btn-action-incomming btn-round btn-size-55 display-table-cell border-none mt-15-negative btn-red ${hangupBtn}`}>
                                                <img src={icon_phone} className="icon-gray" style={{ transform: 'rotate(135deg)' }} />
                                            </button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <audio ref={this.audioRef} />
                        </div>
                    }>
                    {
                        checkPermission('customer', 'voicecall') ? (
                            <Button type='primary' onClick={() => this.isOpenPopover()} icon={<PhoneOutlined />}>Gọi điện</Button>
                        ) : null
                    }
                </Popover>
            </>

        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAccessToken: () => dispatch(getAccessToken())
    }
}

export default connect(null, mapDispatchToProps)(Form.create({ name: 'Stringee' })(Stringee));
