import api from '../../utils/api';
import {
    GET_LIST_SERVICES_PROVIDED,
    GET_SPECIFIC_SERVICES_PROVIDED,
    UPDATE_SPECIFIC_SERVICES_PROVIDED,
    CREATE_NEW_SERVICES_PROVIDED,
    REMOVE_SERVICES_PROVIDED,
    GET_STATISTICAL_ERVICES_PROVIDED
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListServicesProvided = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/services-provided', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_SERVICES_PROVIDED, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificServicesProvided = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/services-provided/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_SERVICES_PROVIDED, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createServicesProvided = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/services-provided', data).then(res => {
            dispatch({ type: CREATE_NEW_SERVICES_PROVIDED, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateServicesProvided = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/services-provided/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_SERVICES_PROVIDED, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeServicesProvided = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/services-provided`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_SERVICES_PROVIDED, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getStatistical = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/services-provided/statistical/total', { params: filter }).then(res => {
            //console.log(res.data)
            dispatch({
                type: GET_STATISTICAL_ERVICES_PROVIDED,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}