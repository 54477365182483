import {
    GET_LIST_CONTACTS,
    GET_SPECIFIC_CONTACTS,
    UPDATE_SPECIFIC_CONTACTS,
    CREATE_NEW_CONTACTS,
    REMOVE_CONTACTS
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    contactsList: [],
    currentContacts: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_CONTACTS: {
            return {
                ...state,
                contactsList: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_SPECIFIC_CONTACTS: {
            return { ...state, currentContacts: action.payload }
        }
        case CREATE_NEW_CONTACTS: {
            return {
                ...state,
                contactsList: [action.payload, ...state.contactsList],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_CONTACTS: {
            let index = state.contactsList.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.contactsList];
            temp[index] = action.payload;
            return { ...state, contactsList: temp }
        }
        case REMOVE_CONTACTS: {
            let temp = state.contactsList.filter(item => {
                console.log(action.payload)
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, contactsList: temp }
        }
        default: return { ...state };
    }
}
