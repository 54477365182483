import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { isMobile } from 'react-device-detect';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';

var { Content } = Layout;

class AppLayout extends Component {

    componentDidMount() {
        //console.log(this.state)
    }

    render() {
        return (
            <Layout>
                <AppHeader />
                <Layout>
                    {
                        isMobile ? null : <AppSidebar />
                    }
                    <Layout style={{ padding: '0 15px 15px' }}>
                        <Content className="content-wrapper">
                            {this.props.children}
                        </Content>
                        {/* <AppFooter /> */}
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
const mapStateToProps = state => {
    //console.log(state)
    return {
        authUser: state.auth.authUser
    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}

//export default AppLayout;
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);