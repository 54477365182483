import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Tag, Button, Row, Col } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import CustomerEditForm from './ContactsEditForm';

// actions

class ContactsDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentItem: null
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem } = this.props;
        //console.log(currentItem)

        return (
            <>
                <Drawer
                    visible={visible}
                    title={currentItem && currentItem.full_name ? currentItem.full_name : "Chi tiết khách hàng"}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '70%'}
                    extra={
                        checkPermission('customer', 'update') ? (
                            <Button type="primary" onClick={() => this.toggleOpenEditForm(true)} icon={<EditOutlined />}>Sửa</Button>
                        ) : null
                    }
                >
                    {visible ? (
                        <div>
                            <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }}>
                                Thông tin khách hàng
                            </Divider>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Tên khách hàng">
                                                    {currentItem ? currentItem.full_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Tên facebook">
                                                    {currentItem ? currentItem.fb_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Số điện thoại">
                                                    {
                                                        checkPermission('customer', 'phonenumber') ? (
                                                            <>{currentItem ? currentItem.phone : null}</>
                                                        ) : (
                                                            <>******</>
                                                        )
                                                    }
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Email">
                                                    {currentItem ? currentItem.email : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Năm sinh">
                                                    {currentItem ? moment(currentItem.birthday).format('DD/MM/YYYY') : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Sở thích">
                                                    {currentItem ? currentItem.fb_interests : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Khu vực">
                                                    {currentItem ? currentItem.fb_district : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Nguồn">
                                                    {currentItem ? currentItem.fb_source : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider className="no-margin" />
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Giới tính">
                                            {currentItem ? currentItem.gender : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Link facebook">
                                            {currentItem ? currentItem.fb_link : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider className="no-margin" />
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Địa chỉ">
                                            {currentItem ? currentItem.province_name : null} -&nbsp;
                                            {currentItem ? currentItem.district_name : null} -&nbsp;
                                            {currentItem ? currentItem.wards_name : null} -&nbsp;
                                            {currentItem ? ReactHtmlParser(currentItem.address) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider orientation="left" orientationMargin="0">
                                Thông tin chung
                            </Divider>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Loại tương tác">
                                                    <Tag>{currentItem ? currentItem.fb_Interactive_type : null}</Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Trạng thái tương tác">
                                                    <Tag>{currentItem ? currentItem.fb_Interactive_status : null}</Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Trạng thái">
                                                    <Tag color='#52c41a'>{currentItem ? currentItem.customer_status : null}</Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Dịch vụ quan tâm">
                                                    {currentItem ? currentItem.interested_service : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Dịch vụ chốt">
                                                    {currentItem ? currentItem.selected_service : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Hạng khách hàng">
                                                    <Tag color="#f39c12">{currentItem ? currentItem.customer_class : null}</Tag>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Lịch hẹn">
                                                    {currentItem ? moment(currentItem.appointment_schedule).format('DD/MM/YYYY HH:mm') : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                        <Divider className="no-margin" />
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Đặt lịch tư vấn">
                                                    {currentItem ? moment(currentItem.schedule_consultation).format('DD/MM/YYYY HH:mm') : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Divider className="no-margin" />
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ghi chú">
                                            {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                            <Divider />
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo khách hàng">
                                            {currentItem ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm') : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Người tạo">
                                            {currentItem ? currentItem.created_user : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </div>
                    ) : null}

                </Drawer>
                <CustomerEditForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ContactsDetail' })(ContactsDetail));
