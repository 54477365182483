import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Divider, Tag, Row, Col, Card, Descriptions, Typography, Space, Image } from 'antd'
import { CalendarOutlined } from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate } from '../../utils/helpers';

const { Text } = Typography;

class CustomerDirectConsultantDetail extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        var { currentItem } = this.props;

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-3'>
                    <Tag color='#0b2b33'>5</Tag> Tư vấn trực tiếp
                </Divider>
                {
                    currentItem && currentItem?.direct_consultant.length ? (
                        <Row gutter={16} className='mb-3 row-customer-direct-consultant'>
                            {
                                currentItem?.direct_consultant.map(item => {
                                    return (
                                        <Col xs={24} md={12} className='mb-3'>
                                            <Card size="small"
                                                title={<>{<CalendarOutlined />} Thời điểm tư vấn: {isValidDate(item.time_consultant)}</>}
                                            >
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Trạng thái">
                                                            {
                                                                item?.status == 0 ? (
                                                                    <Tag color='#f5b22d'>Chưa trả kết quả</Tag>
                                                                ) : null
                                                            }
                                                            {
                                                                item?.status == 1 ? (
                                                                    <Tag color='green'>Đã trả kết quả</Tag>
                                                                ) : null
                                                            }
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Nhân viên tư vấn">
                                                            {
                                                                Array.isArray(item?.consultant_direct_staff) && item?.consultant_direct_staff.length ? (
                                                                    <>
                                                                        {
                                                                            item?.consultant_direct_staff.map(staff => {
                                                                                return (
                                                                                    <Tag>{staff.staff_consultant_direct}</Tag>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                ) : null
                                                            }
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ngày tạo">
                                                            {isValidDate(item.created_at)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Người tạo">
                                                            {item.created_user}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Cập nhật lần cuối">
                                                            {item.user_update_result}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Text strong className='mt-1'>Kết quả tư vấn: </Text>
                                                    {ReactHtmlParser(item.result_content)}
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Text strong style={{ display: 'block' }} className='mb-1 mt-2'>Ảnh: </Text>
                                                    {
                                                        Array.isArray(item?.images) && item?.images.length ? (
                                                            <Image.PreviewGroup>
                                                                <Space wrap>
                                                                    {
                                                                        item.images.map(item => {
                                                                            return (
                                                                                <Image
                                                                                    height={30}
                                                                                    src={item?.src}
                                                                                />
                                                                            )
                                                                        })
                                                                    }
                                                                </Space>
                                                            </Image.PreviewGroup>
                                                        ) : null
                                                    }
                                                </Col>
                                            </Card>
                                        </Col >
                                    )
                                })
                            }
                        </Row >
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }
            </>
        )
    }
}


function mapStateToProps(state) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerDirectConsultantDetail' })(CustomerDirectConsultantDetail));
