import api from '../../utils/api';
import {
    GET_CONVERSATIONS,
    GET_MESSAGES,
    UPDATE_HAS_READ_SNIPPET
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListConversation = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/page-conversations/conversations/page`, { params: filter }).then(res => {
            dispatch({ type: GET_CONVERSATIONS, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getListMessages = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/page-conversations/list-messages', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_MESSAGES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const updateHasRead = (message_ids, has_read) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post(`/page-conversations/update-hasread`, { message_ids: [message_ids], has_read: has_read }).then(res => {
            dispatch({ type: UPDATE_HAS_READ_SNIPPET, payload: res });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

// export const moveOldChatMessages = () => dispatch => {
//     return new Promise((resolve, reject) => {
//         return api.post(`/page-conversations/move-old-chat-messages`).then(res => {
//             dispatch({ type: UPDATE_HAS_READ_SNIPPET, payload: res });
//             resolve(res.data);
//         }).catch(err => {
//             console.log(err)
//             NotificationManager.error(err.response.data.msg);
//             reject(err);
//         })
//     })
// }