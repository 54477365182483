import api from '../../utils/api';
import {
    STRINGEE_GET_TOKEN
} from './types';
import { NotificationManager } from 'react-notifications';

export const getAccessToken = () => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/stringee/access-token').then(res => {
            dispatch({ type: STRINGEE_GET_TOKEN, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}