import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, EyeOutlined, PhoneOutlined } from '@ant-design/icons';
import { Table, Modal, Tooltip, Tag, Divider, DatePicker, Rate, Button, Avatar } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import ContactsAddForm from './ContactsAddForm';
import ContactsEditForm from './ContactsEditForm';
import ContactsDetail from './ContactsDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import Stringee from '../../components/Stringee';

import moment from 'moment';
// actions
import { getListContacts, getSpecificContacts, removeContacts } from '../../redux/actions/ContactsActions';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/contacts',
        breadcrumbName: 'Danh sách liên hệ',
    },
]

class ContactsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenEditForm: false,
            isOpenAddForm: false,
            currentItem: null,
            isOpenDetail: false,
            currentData: null,
            callToNumber: null,
            callToAlias: null
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListContacts(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListContacts(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenEditForm(isOpenEditForm, currentItem = null) {
        this.setState({ isOpenEditForm: isOpenEditForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificContacts(id).then(res => {
            this.toggleOpenEditForm(true, res);
        })
    }

    openAddForm(isOpenAddForm) {
        this.setState({ isOpenAddForm: isOpenAddForm })
    }

    onRemove() {
        this.props.removeContacts(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeContacts(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificContacts(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    makeStringeeCall(callToNumber, callToAlias) {
        this.setState({ callToNumber: callToNumber, callToAlias: callToAlias, openPopover: true })
    }

    render() {
        var { contactsList, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenEditForm,
            isOpenAddForm, currentItem,
            isOpenDetail, currentData,
            current_id,
            callToNumber,
            callToAlias,
            openPopover
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: 'Actions',
                width: '180px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('contacts', 'voicecall') ? (
                                <>
                                    <Button onClick={() => this.makeStringeeCall(record.phone_number, record.customer ? record.customer : record.name)} size='small' icon={<PhoneOutlined />}>Gọi</Button>
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        }
                        {/* {
                            checkPermission('contacts', 'detail') ? (
                                <>
                                    <Tooltip title="Xem chi tiết" placement="right">
                                        <EyeOutlined className='color-primary' onClick={() => this.onDetail(record.id)} />
                                    </Tooltip>
                                    <Divider type='vertical' />
                                </>
                            ) : null
                        } */}
                        {
                            checkPermission('contacts', 'update') ? (
                                <>
                                    <Tooltip title="Chỉnh sửa" placement="right">
                                        <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                        <Divider type='vertical' />
                                    </Tooltip>
                                </>
                            ) : null
                        }
                        {
                            checkPermission('contacts', 'remove') ? (
                                <>
                                    <Tooltip title="Xóa" placement="right">
                                        <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                                    </Tooltip>
                                </>
                            ) : null
                        }
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'customer',
                key: 'customer',
                render: (text, record) => {
                    if (record.customer) {
                        return (
                            <>
                                <Avatar
                                    style={{
                                        backgroundColor: '#31cdf5',
                                        verticalAlign: 'middle'
                                    }}
                                    size="small">{record.customer ? getFirstLetterOfWords(record.customer) : ''}</Avatar>
                                &nbsp;{record.customer}
                            </>
                        )
                    } else {
                        return (
                            <>
                                <Avatar
                                    style={{
                                        backgroundColor: '#31cdf5',
                                        verticalAlign: 'middle'
                                    }}
                                    size="small">{getFirstLetterOfWords(record.name ? record.name : 'Liên hệ')}</Avatar>
                                {
                                    checkPermission('contacts', 'phonenumber') ? (
                                        <>
                                            &nbsp;{record.name ? record.name : `Liên hệ ${record.phone_number}`}
                                        </>
                                    ) : (
                                        <>
                                            &nbsp;{record.name ? record.name : `Liên hệ ******`}
                                        </>
                                    )
                                }

                            </>
                        )
                    }
                }
            },
            {
                title: 'Số điện thoại',
                dataIndex: 'phone_number',
                key: 'phone_number',
                render: (text, record) => {
                    if (checkPermission('contacts', 'phonenumber')) {
                        return (
                            <>{text}</>
                        )
                    } else {
                        return (<>**********</>)
                    }
                }
            },
            {
                title: 'Xếp hạng',
                dataIndex: 'rating',
                key: 'rating',
                render: (text, record) => (
                    <Rate className='rating-start' disabled defaultValue={text} />
                )
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY HH:mm')}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Liên hệ" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('contacts', 'create')}
                        isShowDeleteButton={checkPermission('contacts', 'remove')}
                        onAdd={() => this.openAddForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_date_start
                        }
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'to']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />
                            ]
                        }
                    >
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={contactsList}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <ContactsAddForm
                    visible={isOpenAddForm}
                    onCancel={() => this.openAddForm(false)}
                />
                <ContactsEditForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => false}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />
                <ContactsDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
                <Stringee
                    callToNumber={callToNumber}
                    callToAlias={callToAlias}
                    openPopover={openPopover}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        contactsList: state.ContactsReducer.contactsList,
        pagination: state.ContactsReducer.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListContacts: (filter) => dispatch(getListContacts(filter)),
        getSpecificContacts: (id) => dispatch(getSpecificContacts(id)),
        removeContacts: (ids) => dispatch(removeContacts(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ContactsList' })(ContactsList));

