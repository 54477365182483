import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, Rate, Modal } from 'antd';
import { isMobile } from 'react-device-detect';

// actions
import { updateContacts } from '../../redux/actions/ContactsActions';
import { _newLine } from '../../utils/helpers';

class ContactsEditForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }
    componentDidMount() {

    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                if (this.props.currentItem) {
                    await this.props.updateContacts(this.props.currentItem.id, values);
                    this.onClose();
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        var { } = this.state;
        var {
            visible,
            currentItem
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title="Sửa bản ghi"
                onOk={(e) => this.submit(e)}
                onCancel={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={6}>
                                <Form.Item label="Tên">
                                    {getFieldDecorator('name', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : ""
                                    })(<Input />)}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={6}>
                                <Form.Item label="Số điện thoại">
                                    {getFieldDecorator("phone_number", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.phone_number : ""
                                    })(
                                        <Input />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label="Xếp hạng">
                                    {getFieldDecorator('rating', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.rating : 0
                                    })(
                                        <Rate defaultValue={currentItem ? currentItem.rating : 0} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateContacts: (id, data) => dispatch(updateContacts(id, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ContactsEditForm' })(ContactsEditForm));
