import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AppLayout from '../layouts';
import Routes from '../routes';
import Login from '../routes/auth/Login';
import ResetPassword from '../routes/auth/ResetPassword';
import { Spin } from 'antd';
import Helmet from 'react-helmet';
// App locale
import AppLocale from '../lang';
import { IntlProvider } from 'react-intl';

// actions
import { getAuthUser } from '../redux/actions/AuthActions';
import { getConfig } from '../redux/actions/ConfigActions';
import { getCustomerClassActive } from '../redux/actions/CustomerClassActions';
import { getCustomerStatusActive } from '../redux/actions/CustomerStatusActions';
import { getServiceActive } from '../redux/actions/ServiceActions';
import { getCustomerInteractiveStatusActive } from '../redux/actions/CustomerInteractiveStatusActions';
import { getCustomerInteractiveTypeActive } from '../redux/actions/CustomerInteractiveTypeActions';

class MainApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
        }
    }
    async componentDidMount() {
        try {
            await this.props.getAuthUser();
            await this.props.getConfig();
            await this.props.getCustomerClassActive();
            await this.props.getCustomerStatusActive();
            await this.props.getServiceActive();
            await this.props.getCustomerInteractiveStatusActive();
            await this.props.getCustomerInteractiveTypeActive();
            this.setState({ isLoading: false });

        } catch (err) {
            this.setState({ isLoading: false });
        }
    }

    render() {
        var { isLoading } = this.state;
        var { pathname } = this.props.location;
        const currentAppLocale = AppLocale['en'];
        //console.log("pathname: ", pathname)

        if (isLoading) {
            return (
                <div className="mt-4 text-center" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <Spin size="large" />
                </div >
            )
        }

        var title = 'CHINGO DENTAL';

        switch (pathname) {
            case '/login': {
                return (
                    <Login />
                );
            }
            case '/reset-password': {
                return (
                    <ResetPassword />
                );
            }
            default: {
                return (
                    <IntlProvider
                        locale={'en'}
                        messages={currentAppLocale.messages}
                    >
                        <AppLayout>
                            <Helmet>
                                <title>{title}</title>
                            </Helmet>
                            <Routes />
                        </AppLayout>
                    </IntlProvider>
                )
            }
        }

    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAuthUser: () => dispatch(getAuthUser()),
        getConfig: () => dispatch(getConfig()),
        getCustomerClassActive: (filter) => dispatch(getCustomerClassActive(filter)),
        getCustomerStatusActive: (filter) => dispatch(getCustomerStatusActive(filter)),
        getServiceActive: (filter) => dispatch(getServiceActive(filter)),
        getCustomerInteractiveStatusActive: (filter) => dispatch(getCustomerInteractiveStatusActive(filter)),
        getCustomerInteractiveTypeActive: (filter) => dispatch(getCustomerInteractiveTypeActive(filter))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainApp));