import React, { Component } from 'react';
import '../../assets/css/chatbox.css';
import { Avatar, Drawer, Empty, Row } from 'antd';
import {
    LoadingOutlined,
    UserOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import qs from 'qs';
import { stripHtml, formatRelativeDate, handleAttachmentMessage, toNewLine } from '../../utils/helpers';
import appConfig from '../../config';
import axios from 'axios';
import moment from 'moment';
import no_profile from '../../assets/img/no_profile.jpg';
import { NotificationManager } from 'react-notifications';
import ReactHtmlParser from 'react-html-parser';
import socketio from "socket.io-client";
import { isMobile } from 'react-device-detect';
// actions
import { getListMessages } from '../../redux/actions/ConversationsActions';

class ChatSpecific extends Component {
    constructor() {
        super();
        this.state = {
            messageText: '',
            show_loading_send_msg: false,
            list_messages: [],
            sender: undefined,
            sender_specific: undefined,
            currentpage: undefined,
            is_openChatSpecifix: false,
            socket: socketio.connect(appConfig.SERVER_NODEJS_URL)
        }
        this.textInput = React.createRef();
        this.myFormRef = React.createRef();
    }

    componentDidMount() {
        // Nhận event từ webhook facebook
        this.state.socket.on('post_to_client', (data) => {
            //console.log("data: ", data)
            console.log("Socket event.....");
            var { list_messages, is_openChatSpecifix } = this.state;
            if (data.messages.type == 'messages') {
                if (list_messages && is_openChatSpecifix && data.messages.is_sender != 'page') {
                    this.setState({
                        list_messages: {
                            messages: [...list_messages.messages, data.messages],
                            sender: data.sender,
                            currentpage: data.currentpage
                        }
                    });
                }
            }
        })
    }

    async componentDidUpdate(prevProps, prevState) {
        this.scrollToBottom();
        if (prevProps.currentData != this.props.currentData) {
            const { currentData, visible } = this.props;
            //console.log("currentData: ", currentData)
            var thread_id = currentData.thread_id;
            var psid = currentData.psid;
            this.setState({ is_openChatSpecifix: visible })
            if (psid) {
                await this.props.getListMessages({ thread_id: thread_id, sender_id: psid, type: "messages" }).then((res) => {
                    //console.log("res: ", res)
                    this.setState({
                        list_messages: res.data,
                        sender: res.data.sender,
                        sender_specific: res.data.sender_specific,
                        currentpage: res.data.currentpage,
                    });
                })
            }
        }
    }

    scrollToBottom() {
        const element = document.getElementById('messages2');
        if (element) {
            element.scrollIntoView({ block: "end" });
        }
    }

    handleInput(e) {
        if (this.textInput.current) {
            this.textInput.current.style.height = "auto";
            this.textInput.current.style.height = `${e.target.scrollHeight - 16}px`;
        }
    };

    // Gửi tin nhắn cho user trên page
    submitMessage(e) {
        e.preventDefault();
        var value = stripHtml(this.textInput.current.value).trim();
        value = value.replace(/\&|_/gi, 'và'); // Xóa dấu &
        value = value.replace(/\#|_/gi, '$'); // Xóa dấu #
        var { currentpage, sender } = this.state;
        //console.log("sender: ", sender)

        if (value != '' && value.trim().length) {
            this.textInput.current.value = '';
            this.textInput.current.focus();
            this.textInput.current.style.height = "auto";
            var recipientObj = { id: sender.sender_id }
            var recipient = JSON.stringify(recipientObj);
            var messageObj = { text: value };
            var message = JSON.stringify(messageObj);
            var access_token_page = currentpage.page_access_token;
            var page_id = currentpage.page_id;
            let url = `${appConfig.GRAPH_API}/${appConfig.GRAPH_VERSION}/${page_id}/messages?recipient=${recipient}&messaging_type=RESPONSE&message=${message}&access_token=${access_token_page}`;
            this.setState({ show_loading_send_msg: true })

            const new_msg = {
                thread_id: '',
                message: value,
                from_name: currentpage.page_name,
                from_id: page_id,
                from_picture: currentpage.page_picture,
                created_time: moment(),
                message_type: 'text',
                is_sender: 'page'
            }
            this.setState({
                list_messages: {
                    messages: [...this.state.list_messages.messages, new_msg],
                    sender: this.state.list_messages.sender
                }
            })

            axios.post(url).then((response) => {
                if (response.status == 200) {
                    this.setState({ show_loading_send_msg: false })
                    console.log("Send successfully: ", response.data);
                    this.playerSendSound();
                }
            }).catch(function (error) {
                if (error.response) {
                    NotificationManager.error(error.response.data.error.message);
                }
            });
        }
    }

    onEnterPress = (e) => {
        if (e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();
            this.myFormRef.requestSubmit();
        }
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    render() {
        var {
            list_messages,
            sender,
            sender_specific,
            show_loading_send_msg,
            currentpage,
        } = this.state;
        var {
            visible,
        } = this.props;

        return (
            <Drawer
                visible={visible}
                title={
                    sender_specific ? (
                        <>
                            <Avatar icon={<UserOutlined />} size={40} src={sender_specific.sender_picture} /> &nbsp; {sender.sender_name}
                        </>
                    ) : null
                }
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '45%'}
                footer={null}
            >
                <>
                    <div className="content">
                        {list_messages && list_messages.messages && list_messages.messages.length ? (
                            <div className="messages">
                                <ul id='messages-ul'>
                                    {
                                        list_messages.messages.map(item => { // sent, replies
                                            return (
                                                <li key={item.id} className={item.is_sender == 'page' ? 'replies' : 'sent'}>
                                                    {item.from_picture ? (
                                                        // <img src={item.from_picture} alt="" />
                                                        <Avatar icon={<UserOutlined />} className='avata' size={22} src={item.from_picture} />
                                                    ) : (
                                                        <img src={no_profile} alt="" />
                                                    )}
                                                    <div>
                                                        {
                                                            item.message_type == 'text' ? (
                                                                <>{ReactHtmlParser(toNewLine(item.message))}</>
                                                            ) : (
                                                                <>{handleAttachmentMessage(item.attachment_type, item.message)}</>
                                                            )
                                                        }
                                                        <div class="hover-info">
                                                            <div class="hover-time">
                                                                {formatRelativeDate(item.created_time)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                    <li id='messages2'></li>
                                </ul>
                            </div>
                        ) : (
                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center', marginRight: 0 }} key={1}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Row>
                        )}
                        <div className="message-input">
                            <LoadingOutlined style={{ display: show_loading_send_msg ? 'block' : 'none' }} className='loading-messages' />
                            <div className="wrap">
                                <form onSubmit={(e) => this.submitMessage(e)} ref={el => this.myFormRef = el}>
                                    {currentpage != undefined && currentpage.page_picture ? (
                                        // <img className='avata-page' src={currentpage.page_picture} alt={currentpage.page_name} />
                                        <Avatar icon={<UserOutlined />} className='avata-page' size={30} src={currentpage.page_picture} />
                                    ) : (
                                        <img className='avata-page' src={no_profile} alt="" />
                                    )}

                                    <textarea
                                        ref={this.textInput}
                                        onKeyDown={this.onEnterPress}
                                        placeholder="Nhấn Enter để gửi, Shift + Enter để xuống dòng. Mỗi tin không quá 2000 ký tự..."
                                        rows={1}
                                        type="text"
                                        onInput={(e) => this.handleInput(e)}
                                    >
                                    </textarea>

                                    <div className='list-attachment-icon-wrap'>
                                        <i class="fas fa-image icon picture" aria-hidden="true"></i>
                                        <button type='submit' className="submit" onClick={(e) => this.submitMessage(e)}>
                                            <i className="fa fa-paper-plane icon" aria-hidden="true" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            </Drawer >
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListMessages: (filter) => dispatch(getListMessages(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ChatSpecific' })(ChatSpecific));
