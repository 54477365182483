import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SendOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Input, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import { Form } from '@ant-design/compatible';
import TableActionBar from '../../components/TableActionBar';
import { formatRelativeDate, getFirstLetterOfWords } from '../../utils/helpers';
import socketio from "socket.io-client";
import appConfig from '../../config';

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/chatsocket',
        breadcrumbName: 'Chat demo',
    },
]

class ChatSocket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messages: [],
            socket: socketio.connect(appConfig.SERVER_NODEJS_URL),
            isConnected: false,
            userData: {
                user_id: this.props.authUser.id,
                username: this.props.authUser.full_name,
                socket_id: '',
            },
        };
        this.chatContainerRef = React.createRef();
    }

    componentDidMount() {
        this.state.socket.on('connect', () => {
            this.setState({
                userData: {
                    ...this.state.userData,
                    socket_id: this.state.socket.id
                },
                isConnected: true
            });
            this.state.socket.emit('user_connected', this.state.userData);
        });

        this.state.socket.on('post_to_client', (data) => {
            const newMessage = data;
            this.setState((prevState) => ({
                messages: [...prevState.messages, JSON.stringify(newMessage)],
            }));
        });

        this.state.socket.on('old_messages', (messages) => {
            const parsedMessages = messages.map(msg => JSON.stringify(msg));
            console.log('Old messages:', parsedMessages);
            this.setState({ messages: parsedMessages });
        });

        this.state.socket.on('conversations_list', (conversations) => {
            console.log('conversations list:', conversations);
            this.setState({ conversations: conversations });
        });

        this.state.socket.on('disconnect', () => {
            this.handleConnectionStatus(false);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const chatContainer = this.chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }

    handleConnectionStatus = (isConnected) => {
        this.setState({ isConnected });
    }

    sendMessage = () => {
        const { socket, message, userData } = this.state;

        if (message.trim() !== '') {
            const newMessage = {
                ...userData,
                message,
                created_at: new Date().toISOString(), // Thêm thời gian tạo
                socket_id: socket.id // Add socket_id to the message
            };

            // Gửi tin nhắn và thông tin người dùng lên server
            socket.emit('post_to_client', newMessage);
            console.log('Message sent to server:', newMessage);

            this.setState(prevState => ({
                message: '',
                //messages: [...prevState.messages, JSON.stringify(newMessage)],
            }));
        }
    };

    componentWillUnmount() {
        const { socket } = this.state;
        if (socket) {
            socket.close();
        }
    }

    onChangeInput(value) {
        this.setState({ message: value })
    }

    render() {
        const { message, messages, userData, isConnected } = this.state;

        var jsonArray = messages.map(function (jsonString) {
            return JSON.parse(jsonString);
        });
        console.log(jsonArray);

        return (
            <div>
                <PageTitle routes={routes} title="" />
                <TableActionBar
                    isShowAddButton={false}
                    isShowDeleteButton={false}
                    showFilter={false}
                    showSearch={false}
                ></TableActionBar>

                <Row gutter={16}>
                    <Col xs={24} md={18}>
                        <Card>
                            {isConnected ? null : (
                                <div style={{ color: 'red', textAlign: 'center', margin: '10px' }}>
                                    Không thể kết nối đến server. Vui lòng kiểm tra lại kết nối của bạn.
                                </div>
                            )}
                            <div ref={this.chatContainerRef} className="chat-container">
                                {jsonArray.map((msg, index) => (
                                    <div
                                        key={index}
                                        className={`message-container ${userData.socket_id === msg.socket_id ? 'right' : 'left'}`}
                                    >
                                        <p className="message-user">
                                            {
                                                userData.socket_id === msg.socket_id ? (
                                                    <>
                                                        {msg.username}
                                                        <Divider type='vertical' />
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: '#31cdf5',
                                                                verticalAlign: 'middle'
                                                            }}
                                                            size="large">
                                                            {msg.username ? getFirstLetterOfWords(msg.username) : ''}
                                                        </Avatar>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: '#31cdf5',
                                                                verticalAlign: 'middle'
                                                            }}
                                                            size="large">
                                                            {msg.username ? getFirstLetterOfWords(msg.username) : ''}
                                                        </Avatar>
                                                        <Divider type='vertical' />
                                                        {msg.username}
                                                    </>
                                                )
                                            }
                                        </p>
                                        <p className="message">{msg.message}</p>
                                        <div class="message-time">
                                            {formatRelativeDate(msg.created_at)}
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="input-container">
                                <Input
                                    type="text"
                                    value={message}
                                    onChange={(e) => this.onChangeInput(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            this.sendMessage();
                                        }
                                    }}
                                />
                                <Divider type='vertical' />
                                <Button type="primary" icon={<SendOutlined />} onClick={this.sendMessage}>
                                    Send
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ChatSocket' })(ChatSocket));
