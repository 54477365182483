import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Card, Form, Input, Button, Row, Col, Descriptions, Tag, Divider, Modal, Alert } from 'antd';
import PageTitle from '../../components/PageTitle';
import { isMobile } from 'react-device-detect';
import { CheckCircleTwoTone, CloseCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { changePassword, updateAuthUser, changePinCode, resetPincode } from '../../redux/actions/AuthActions';
import { isValidDate } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications';
import { register } from './Webauthn';


const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/profile',
        breadcrumbName: 'Thông tin tài khoản',
    },
]

const { confirm } = Modal;

class Profile extends Component {
    state = {
        loading: false,
        isloading: false,
        visible: false,
        confirmLoading: false
    }

    formRef = React.createRef();

    handleSubmit = (values) => {
        this.setState({ loading: true });
        this.props.changePassword(values).then(() => {
            this.formRef.current.resetFields();
            this.setState({ loading: false })
        }).catch(() => {
            this.setState({ loading: false })
        })
    }

    handleChangePinCode = (values) => {
        this.setState({ isloading: true });
        this.props.changePinCode(values).then(() => {
            this.formRef.current.resetFields();
            this.setState({ isloading: false })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }).catch(() => {
            this.setState({ isloading: false })
        })
    }

    handleCancel() {
        this.setState({ visible: false, confirmLoading: false })
    }

    updateAccount = async (values) => {
        this.setState({ confirmLoading: true });
        await this.props.updateAuthUser(values).then((res) => {
            this.handleCancel()
        }).catch(() => {
            this.setState({ confirmLoading: false })
        })
    }

    handleResetPinCode() {
        confirm({
            title: 'Cảnh báo',
            content: <>Hệ thống sẽ gửi mã pin mới đến địa chỉ email <b>{this.props.authUser?.user_email}</b>. <br /> Bạn chắc chắn muốn reset mã pin?</>,
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: async () => {
                await this.props.resetPincode()
                    .then((res) => {
                        NotificationManager.success("Reset mã pin thành công!");
                    })
                    .catch((err) => {
                        NotificationManager.error("Có lỗi xảy ra. Vui lòng thử lại.");
                    });
            },
            onCancel() { },
        })
    }

    handleRegister = async () => {
        var { authUser } = this.props;
        await register(authUser);
    }

    render() {
        var { loading, isloading, confirmLoading, visible } = this.state;
        var { authUser } = this.props;

        return (
            <div>
                <PageTitle routes={routes} />
                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Card
                            className="mb-4 mt-3 h-100"
                            title="Tài khoản"
                            size='small'
                            extra={
                                <Button onClick={() => this.setState({ visible: true })}
                                    size='small'
                                    type='primary'
                                    icon={<EditOutlined />}>
                                    Cập nhật
                                </Button>}
                        >
                            <Row gutter={24}>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Full Name">
                                            {authUser?.full_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Username">
                                            {authUser?.user_name}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Email">
                                            {authUser?.user_email}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Vai trò">
                                            <Tag color="cyan">{authUser?.role_name}</Tag>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Số điện thoại">
                                            {authUser?.phone_number}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="ID telegram">
                                            {authUser?.telegram_id}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Trạng thái">
                                            {
                                                authUser && authUser?.user_active == 1 ? <>`<CheckCircleTwoTone twoToneColor="#52c41a" /> Active</> : null
                                            }
                                            {
                                                authUser && authUser?.user_active == 0 ? <><CloseCircleTwoTone twoToneColor="#e74c3c" /> Inactive</> : null
                                            }
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cơ sở làm việc chính">
                                            {authUser?.primary_workplace}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Cơ sở làm việc tạm thời">
                                            {authUser?.temporary_workplace}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Thời gian làm việc tạm thời">
                                            {isValidDate(authUser?.temporary_work_duration_start, false)} -&nbsp;
                                            {isValidDate(authUser?.temporary_work_duration_end, false)}
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Divider className="no-margin" />
                                </Col>
                                <Col xs={24} md={24}>
                                    <Descriptions size="small">
                                        <Descriptions.Item label="Ngày tạo">
                                            {authUser ? isValidDate(authUser?.created_at) : null}
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={24} md={12}>
                        <Card className="mb-4 mt-3"
                            size='small'
                            title="Đổi mật khẩu"
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.handleSubmit}
                                ref={this.formRef}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Mật khẩu hiện tại"
                                    name="old_password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng điền mật khẩu hiện tại!'
                                        }
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Mật khẩu mới"
                                            name="new_password"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền mật khẩu mới!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Xác nhận mật khẩu mới"
                                            name="confirm_new_password"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền lại mật khẩu mới!' }
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item style={{ textAlign: 'right' }} className='mb-0'>
                                    <Button type="primary" htmlType="submit" loading={loading}>
                                        Xác nhận
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                        <Card className="mb-4 mt-3"
                            size='small'
                            title="Mã pin"
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.handleChangePinCode}
                                ref={this.formRef}
                                autoComplete="new-password"
                            >
                                {
                                    authUser.pin_code ? (
                                        <Form.Item
                                            label="Mã pin hiện tại"
                                            name="old_pin_code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Vui lòng điền mã pin hiện tại!'
                                                }
                                            ]}
                                        >
                                            <Input.Password autoComplete="new-password" />
                                        </Form.Item>
                                    ) : null
                                }

                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Mã pin mới"
                                            name="new_pin_code"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền mã pin mới!' }
                                            ]}
                                        >
                                            <Input.Password autoComplete="new-password" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={12} sm={24} className="custom-col">
                                        <Form.Item
                                            label="Xác nhận mã pin mới"
                                            name="confirm_new_pin_code"
                                            rules={[
                                                { required: true, message: 'Vui lòng điền lại mã pin mới!' }
                                            ]}
                                        >
                                            <Input.Password autoComplete="new-password" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="space-between">
                                    <Col>
                                        <Form.Item className='mb-0'>
                                            <Button type="link" onClick={() => this.handleResetPinCode()}>
                                                Quên mã pin?
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item className='mb-0'>
                                            <Button type="primary" htmlType="submit" loading={isloading}>
                                                Xác nhận
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16} justify="space-between">
                                    <Col md={24} sm={24} className='mt-4 mb-4'>
                                        <Divider />
                                        <div className='mt-4 mb-4' />
                                        <Button type="primary" onClick={() => this.handleRegister()} block>
                                            Đăng ký nhận diện khuôn mặt
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Modal
                    width={isMobile ? '100%' : '30%'}
                    title="Cập nhật tài khoản"
                    visible={visible}
                    onCancel={() => this.handleCancel()}
                    maskClosable={false}
                    footer={false}
                >
                    <Alert
                        message="Hướng dẫn lấy ID Telegram"
                        className='fix-alert-element'
                        description={
                            <div>
                                <a href='https://erp.chingodental.vn/guide/34' target="_blank">Xem hướng dẫn tại đây</a>
                            </div>
                        }
                        type="info"
                    />
                    <br />
                    <Form
                        layout="vertical"
                        onFinish={this.updateAccount}
                        ref={this.formRef}
                        initialValues={{
                            ...authUser
                        }}
                    >
                        <Form.Item
                            label="ID telegram"
                            name="telegram_id"
                            rules={[
                                { required: false, message: 'Vui lòng điền!' }
                            ]}
                        >
                            <Input placeholder='Nhập id telegram vào đây' />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }} className='mb-0'>
                            <Button type="primary" htmlType="submit" loading={confirmLoading}>
                                Cập nhật
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        config: state.config,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (data) => dispatch(changePassword(data)),
        changePinCode: (data) => dispatch(changePinCode(data)),
        updateAuthUser: (data) => dispatch(updateAuthUser(data)),
        resetPincode: () => dispatch(resetPincode())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);