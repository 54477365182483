import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, TreeSelect } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import ServicesProvidedConsultantOnline from './ServicesProvidedConsultantOnline';
// actions
import { createServicesProvided, updateServicesProvided } from '../../redux/actions/ServicesProvidedActions';
import { getSpecificService } from '../../redux/actions/ServiceActions';
import { _newLine, makeTree } from '../../utils/helpers';

class ServicesProvidedInterestedForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            provided_consultant: []
        };
    }

    getProvidedConsultantItems = data => {
        this.setState({ provided_consultant: data })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { provided_consultant } = this.state;
                    const { currentCustomer } = this.props;

                    const services_interested = {
                        service_id: values.interested_service_id,
                        current_status: values.current_status,
                        provided_consultant: provided_consultant,
                        note: values.note
                    }

                    values.services_interested = services_interested;
                    delete values.note;
                    delete values.current_status;
                    delete values.interested_service_id;

                    if (this.props.currentItem) {
                        await this.props.updateServicesProvided(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        values.customer_id = currentCustomer ? currentCustomer?.id : values.customer_id;
                        await this.props.createServicesProvided(values);
                        this.onClose();
                    }
                    //console.log(values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
            provided_consultant: []
        });
    }

    render() {
        var { isLoading } = this.state;
        var {
            visible,
            currentItem,
            service_active_list,
            currentCustomer
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const service = makeTree(service_active_list || [], 0);
        // console.log("currentCustomer: ", currentCustomer)
        return (
            <Drawer
                visible={visible}
                title={currentItem ? 'Sửa dịch vụ quan tâm' : `Thêm dịch vụ quan tâm`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                placement="right"
                width={isMobile ? '100%' : '70%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                {currentItem ? 'Cập nhật' : "Thêm mới"}
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng: <span className='color-primary'>{`${currentCustomer?.full_name} - ${currentCustomer?.code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label='Dịch vụ'>
                                    {getFieldDecorator("interested_service_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.service_id : null
                                    })(
                                        <TreeSelect
                                            showSearch
                                            style={{ width: '100%' }}
                                            dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                            treeData={service}
                                            treeNodeFilterProp='title'
                                            allowClear
                                            placeholder="Chọn một bản ghi"
                                            treeDefaultExpandAll
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label='Tình trạng hiện tại'>
                                    {getFieldDecorator("current_status", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.current_status) : null
                                    })(<Input.TextArea rows={3} />)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <ServicesProvidedConsultantOnline currentServicesProvidedConsultantOnline={currentItem ? currentItem.consultant : null} getItems={(data) => this.getProvidedConsultantItems(data)} />
                            </Col>
                        </Row>
                        <Row gutter={16} className='mt-4'>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea rows={5} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        service_active_list: state.ServiceReducer.service_active_list
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createServicesProvided: (data) => dispatch(createServicesProvided(data)),
        updateServicesProvided: (id, data) => dispatch(updateServicesProvided(id, data)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ServicesProvidedInterestedForm' })(ServicesProvidedInterestedForm));
