import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Popconfirm, DatePicker, Select } from "antd";
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Option } = Select;

class ServicesProvidedConsultantDirectly extends Component {
    state = {
        items: [{
            key: 0,
            user_id: null,
            consultant_date: "",
            note: ""
        }],
        count: 0 // use for item key, not items length
    }

    componentDidMount() {
        if (this.props.currentServicesProvidedConsultantDirectly) {
            var items = this.props.currentServicesProvidedConsultantDirectly.map((item, index) => {
                //console.log('items: ', item)
                return {
                    key: index + 1,
                    user_id: item.consultant_user_id,
                    consultant_date: item.consultant_date ? moment(item.consultant_date, 'YYYY-MM-DD HH:mm') : null,
                    note: item.note
                }
            });
            this.setState({ items: items, count: items.length }, () => {
                //console.log("this.state.items: ", this.state.items)
                this.getItems(this.state.items);
            });
        }
    }

    onAdd() {
        let index = this.state.count + 1;
        let item = {
            key: index,
            user_id: null,
            consultant_date: "",
            note: ""
        }

        this.setState({
            items: [
                ...this.state.items,
                item
            ],
            count: index
        })
    }

    onDelete(key) {
        let items = this.state.items.filter(item => {
            return item.key != key;
        });

        this.setState({
            items: items
        })
    }

    onChangeItem(name, value, itemKey) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.key == itemKey);
        let currentItem = items[index];
        currentItem[name] = value;
        items[index] = currentItem;
        this.setState({ items: items });
    }

    // getItems() {
    //     this.props.getItems(this.state.items);
    // }

    getItems = () => {
        // Thêm thời gian mặc định từ props nếu chưa có
        const { items } = this.state;
        const updatedItems = items.map(item => ({
            ...item,
            consultant_date: item.consultant_date || this.props.currentDate
        }));

        this.props.getItems(updatedItems);
    }

    render() {
        var { items } = this.state;
        var { config, currentDate } = this.props;
        //console.log("item: ", items)

        var columns = [
            {
                title: "Nhân viên",
                dataIndex: "user_id",
                width: "25%",
                render: (text, record) => {
                    return (
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Chọn nhân viên"
                            optionLabelProp="label"
                            allowClear={true}
                            showSearch={true}
                            defaultValue={text}
                            onChange={(value) => this.onChangeItem('user_id', value, record.key)}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {
                                config && config?.users ? (
                                    <>
                                        {
                                            config?.users.map((item) => {
                                                return (
                                                    <Option key={item.user_id} value={item.user_id} label={item.full_name}>{item.full_name}</Option>
                                                )
                                            })
                                        }
                                    </>
                                ) : null
                            }
                        </Select>
                    )
                }
            },
            {
                title: "Thời điểm tư vấn",
                dataIndex: "consultant_date",
                width: "25%",
                render: (text, record) => {
                    return (
                        <DatePicker
                            value={text || currentDate}
                            name="consultant_date"
                            style={{ width: '100%' }}
                            onChange={(value) => this.onChangeItem("consultant_date", value, record.key)}
                            showTime={{ format: 'HH:mm' }}
                            format="DD/MM/YYYY HH:mm"
                        />
                    )
                }
            },
            {
                title: "Kết quả tư vấn",
                dataIndex: "note",
                width: "50%",
                render: (text, record) => {
                    return (
                        <Input.TextArea
                            value={text}
                            name="note"
                            style={{ height: 16, marginBottom: 0 }}
                            onChange={(e) => this.onChangeItem(e.target.name, e.target.value, record.key)}
                        />
                    )
                }
            },
            {
                title: "#",
                align: "center",
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa dòng này?"
                            onConfirm={() => this.onDelete(record.key)}
                        >
                            <DeleteOutlined className='item-action-btn-remove' />
                        </Popconfirm>
                    )
                }
            }
        ];

        return (
            <div className='no-border-buttom-table w-100' onMouseOut={() => this.getItems()}>
                <Button type="dashed" size='small' onClick={() => this.onAdd()} icon={<UserAddOutlined />}>Thêm nhân viên tư vấn trực tiếp</Button>
                <Table
                    className='mt-2'
                    size='small'
                    rowKey='key'
                    tableLayout="auto"
                    columns={columns}
                    dataSource={items}
                    pagination={false}
                    locale={{ emptyText: "Chưa có dữ liệu" }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    //console.log("state.config: ", state.config)
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesProvidedConsultantDirectly);