import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Col, Row, Divider, DatePicker, Select } from 'antd';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import generatePassword from 'generate-password';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { _newLine, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import UploadImage from '../../components/UploadImage';
import ListCurrentImage from '../../components/ListCurrentImage';
import { NotificationManager } from 'react-notifications'
// actions
import { createUser, updateUser } from '../../redux/actions/UserActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';

const { RangePicker } = DatePicker;

class UserForm extends Component {
    state = {
        loading: false,
        userRole: null,
        isResetUploadAvatar: true,
        isResetUploadDiploma: true,
        isResetUploadIdentity: true,
        isResetUploadCertificate: true,
        isResetUploadDocument: true,
        avatar: [],
        diploma: [],
        identity: [],
        certificate: [],
        document: [],
        currentAvatar: [],
        currentDiploma: [],
        currentIdentity: [],
        currentCertificate: [],
        currentDocument: []
    }

    componentDidMount() {
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user != this.props.user) {
            if (nextProps?.user?.avatar && Array.isArray(nextProps?.user?.avatar)) {
                this.setState({ currentAvatar: nextProps?.user?.avatar })
            } else {
                this.setState({ currentAvatar: [] })
            }
            if (nextProps?.user?.diploma && Array.isArray(nextProps?.user?.diploma)) {
                this.setState({ currentDiploma: nextProps?.user?.diploma })
            } else {
                this.setState({ currentDiploma: [] })
            }
            if (nextProps?.user?.identity_card && Array.isArray(nextProps?.user?.identity_card)) {
                this.setState({ currentIdentity: nextProps?.user?.identity_card })
            } else {
                this.setState({ currentIdentity: [] })
            }
            if (nextProps?.user?.certificate && Array.isArray(nextProps?.user?.certificate)) {
                this.setState({ currentCertificate: nextProps?.user?.certificate })
            } else {
                this.setState({ currentCertificate: [] })
            }
            if (nextProps?.user?.orther_document && Array.isArray(nextProps?.user?.orther_document)) {
                this.setState({ currentDocument: nextProps?.user?.orther_document })
            } else {
                this.setState({ currentDocument: [] })
            }
        }
    }

    onChangeRole(value) {
        this.setState({ userRole: value })
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.avatar;
            delete values.diploma;
            delete values.certificate;
            delete values.identity_card;
            delete values.orther_document;

            if (!err) {
                this.setState({ loading: true });
                try {
                    this.setState({ loadingForm: true })
                    const {
                        currentAvatar,
                        avatar,
                        currentDiploma,
                        diploma,
                        currentIdentity,
                        identity,
                        currentCertificate,
                        certificate,
                        currentDocument,
                        document
                    } = this.state;
                    values.avatar = customDataImageUpload(avatar, currentAvatar);
                    values.diploma = customDataImageUpload(diploma, currentDiploma);
                    values.identity_card = customDataImageUpload(identity, currentIdentity);
                    values.certificate = customDataImageUpload(certificate, currentCertificate);
                    values.orther_document = customDataImageUpload(document, currentDocument);

                    if (values.temporary_work_duration && values.temporary_work_duration.length) {
                        values.temporary_work_duration_start = moment(values.temporary_work_duration[0]).format('YYYY-MM-DD');
                        values.temporary_work_duration_end = moment(values.temporary_work_duration[1]).format('YYYY-MM-DD');
                    } else {
                        values.temporary_work_duration_start = null;
                        values.temporary_work_duration_end = null;
                    }

                    delete values.temporary_work_duration;
                    if (this.props.user) {
                        await this.props.updateUser(this.props.user.id, values);
                        this.props.reloadData();
                        this.onClose();
                    } else {
                        await this.props.createUser(values);
                        this.onClose();
                    }
                    console.log(values)
                } catch (error) {
                    this.setState({ loading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            loading: false,
            avatar: [],
            diploma: [],
            identity: [],
            certificate: [],
            document: [],
            isResetUploadAvatar: true,
            isResetUploadDiploma: true,
            isResetUploadIdentity: true,
            isResetUploadCertificate: true,
            isResetUploadDocument: true
        });
    }

    onChangeAvatar = (data) => {
        this.setState({ isResetUploadAvatar: false, avatar: data });
        NotificationManager.success("Avatar complete.");
    }

    onChangeDiploma = (data) => {
        this.setState({ isResetUploadDiploma: false, diploma: data });
        NotificationManager.success("Diploma complete.");
    }

    onChangeIdentity = (data) => {
        this.setState({ isResetUploadIdentity: false, identity: data });
        NotificationManager.success("Identity card complete.");
    }

    onChangeCertificate = (data) => {
        this.setState({ isResetUploadCertificate: false, certificate: data });
        NotificationManager.success("Certificate complete.");
    }

    onChangeDocument = (data) => {
        this.setState({ isResetUploadDocument: false, document: data });
        NotificationManager.success("Document complete.");
    }

    getImages(stateKey, data) {
        this.setState({ [stateKey]: data })
    }

    render() {
        var { visible, user, config, branchActiveList } = this.props;
        var {
            loading,
            isResetUploadAvatar,
            isResetUploadDiploma,
            isResetUploadIdentity,
            isResetUploadCertificate,
            isResetUploadDocument,
            currentAvatar,
            currentDiploma,
            currentIdentity,
            currentCertificate,
            currentDocument
        } = this.state;

        const { getFieldDecorator } = this.props.form;

        const statuses = [
            { value: 1, label: 'Active' },
            { value: 0, label: 'Inactive' },
        ];

        return (
            <div>
                <Drawer
                    visible={visible}
                    title={user ? `Sửa nhân viên ${user.full_name}` : 'Thêm mới nhân viên'}
                    onClose={() => this.onClose()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '55%'}
                    footer={
                        <div style={{ textAlign: 'right' }}>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Divider type="vertical" />
                            <Button key="submit" type="primary" onClick={(e) => this.submit(e)} loading={loading}>
                                {user ? 'Sửa' : 'Thêm mới'}
                            </Button>
                        </div>
                    }
                >
                    {visible ?
                        <Form layout="vertical">
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Avatar" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentAvatar} getData={(data) => this.getImages('currentAvatar', data)} />
                                        {getFieldDecorator('avatar', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={false}
                                                maxCount={1}
                                                onChangeData={this.onChangeAvatar}
                                                isResetUpload={isResetUploadAvatar}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Họ tên">
                                                {getFieldDecorator('full_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền họ tên!',
                                                        },
                                                    ],
                                                    initialValue: user ? user.full_name : null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Username">
                                                {getFieldDecorator('user_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền username!',
                                                        },
                                                    ],
                                                    initialValue: user ? user.user_name : null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="E-mail">
                                                {getFieldDecorator('user_email', {
                                                    rules: [
                                                        {
                                                            type: 'email',
                                                            message: 'Email không hợp lệ!',
                                                        },
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền E-mail!',
                                                        },
                                                    ],
                                                    initialValue: user ? user.user_email : null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Vai trò user">
                                                {getFieldDecorator('role', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng chọn vai trò!',
                                                        },
                                                    ],
                                                    initialValue: user ? user.role : null
                                                })(
                                                    <Select
                                                        className='w-100'
                                                        placeholder="Chọn vai trò"
                                                        onChange={(value) => this.onChangeRole(value)}
                                                        options={config.role_trees || []}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Số điện thoại">
                                        {getFieldDecorator('phone_number', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền',
                                                },
                                            ],
                                            initialValue: user ? user.phone_number : null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                {/* Only show password input when create user */}
                                {
                                    user ? null : (
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mật khẩu" hasFeedback>
                                                {getFieldDecorator('user_password', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền mật khẩu!',
                                                        },
                                                        {
                                                            validator: this.validateToNextPassword,
                                                        },
                                                    ],
                                                    initialValue: generatePassword.generate({
                                                        length: 10,
                                                        numbers: true
                                                    })
                                                })(<Input.Password autoComplete={"new-password"} className='w-100' />)}
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col xs={24} md={8}>
                                    <Form.Item label="Trạng thái">
                                        {getFieldDecorator('user_active', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Vui lòng chọn trạng thái!',
                                                },
                                            ],
                                            initialValue: user ? user.user_active : 1
                                        })(
                                            <BaseRadios options={statuses} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Cơ sở làm việc chính">
                                        {getFieldDecorator('primary_workplace_branch_ids', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: user ? user.primary_workplace_branch_id ? user.primary_workplace_branch_id : [] : [],
                                            normalize: value => value !== undefined ? value : null
                                        })(
                                            <BaseSelect
                                                options={branchActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn 1 hoặc nhiều cơ sở"
                                                className="w-100"
                                                mode="multiple"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Cơ sở làm việc tạm thời">
                                        {getFieldDecorator('temporary_workplace_branch_id', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền',
                                                },
                                            ],
                                            initialValue: user ? user.temporary_workplace_branch_id : null,
                                            normalize: value => value !== undefined ? value : null
                                        })(
                                            <BaseSelect
                                                options={branchActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                placeholder="Chọn cơ sở"
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Thời gian làm việc tạm thời">
                                        {getFieldDecorator('temporary_work_duration', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền',
                                                },
                                            ],
                                            initialValue: user ? [isValidDateForm(user.temporary_work_duration_start, false), isValidDateForm(user.temporary_work_duration_end, false)] : null
                                        })(
                                            <RangePicker
                                                format="DD/MM/YYYY"
                                                placeholder={['Từ ngày', 'Đến ngày']}
                                                className="w-100"
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="ID telegram">
                                        {getFieldDecorator('telegram_id', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng nhập!',
                                                },
                                            ],
                                            initialValue: user ? user?.telegram_id : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: user ? _newLine(user.note) : null
                                        })(
                                            <Input.TextArea rows={2} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider orientation="left" orientationMargin="0">
                                Giấy tờ liên quan
                            </Divider>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Bằng tốt nghiệp" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentDiploma} getData={(data) => this.getImages('currentDiploma', data)} />
                                        {getFieldDecorator('diploma', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={10}
                                                onChangeData={this.onChangeDiploma}
                                                isResetUpload={isResetUploadDiploma}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="CCCD/CMT" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentIdentity} getData={(data) => this.getImages('currentIdentity', data)} />
                                        {getFieldDecorator('identity_card', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={10}
                                                onChangeData={this.onChangeIdentity}
                                                isResetUpload={isResetUploadIdentity}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Chứng chỉ hành nghề" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentCertificate} getData={(data) => this.getImages('currentCertificate', data)} />
                                        {getFieldDecorator('certificate', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={10}
                                                onChangeData={this.onChangeCertificate}
                                                isResetUpload={isResetUploadCertificate}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Giấy tờ khác" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentDocument} getData={(data) => this.getImages('currentDocument', data)} />
                                        {getFieldDecorator('orther_document', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                multiple={true}
                                                maxCount={10}
                                                onChangeData={this.onChangeDocument}
                                                isResetUpload={isResetUploadDocument}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        : null}
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    // console.log(state)
    return {
        config: state.config,
        branchActiveList: state.BranchManagementReducer.branchActiveList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createUser: (data) => dispatch(createUser(data)),
        updateUser: (id, data) => dispatch(updateUser(id, data)),
        getBranchActive: () => dispatch(getBranchActive()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UserForm' })(UserForm));