import {
    GET_CONVERSATIONS,
    GET_MESSAGES,
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    list_conversations: {
        convertsations: {
            convertsations: [],
            pagination: {
                currentPage: 1,
                total: 0, // total records
                perPage: 30
            }
        },
        list_current_page: []
    },
    list_messages: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONVERSATIONS: {
            return {
                ...state,
                list_conversations: {
                    convertsations: {
                        convertsations: action.payload.conversation.data,
                        pagination: {
                            currentPage: parseInt(action.payload.conversation.current_page),
                            total: parseInt(action.payload.conversation.total),
                            perPage: parseInt(action.payload.conversation.per_page),
                        }
                    },
                    list_current_page: action.payload.page
                }
            }
        }

        case GET_MESSAGES: {
            return {
                ...state,
                list_messages: action.payload
            }
        }
        default: return { ...state };
    }
}
