import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Tag, DatePicker, TreeSelect, Avatar, Button, Typography, Select, Divider, Row, Card } from 'antd';
import { ReloadOutlined, UserOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import ServicesProvidedDetail from './ServicesProvidedDetail';
import CustomerDetail from './../customer/CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, makeTree, getFirstLetterOfWords, isValidDate, repeatPhoneNumber } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import BaseSelect from '../../components/Elements/BaseSelect';
import moment from 'moment';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
// actions
import { getListServicesProvided, getSpecificServicesProvided, getStatistical } from '../../redux/actions/ServicesProvidedActions';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import StatisticNumber from '../../components/StatisticNumber';
import DynamicTable from '../../components/DynamicTable';


const { RangePicker } = DatePicker;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/services-provided',
        breadcrumbName: 'Dịch vụ',
    },
]

const { Paragraph } = Typography;
const { Option } = Select;

class ServicesProvidedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenDetail: false,
            currentData: null,
            isOpenCustomerDetail: false,
            currentCustomerData: null,
            loadingDetail: false,
            selectedValues: []
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListServicesProvided(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getCustomerSourceActive();
        this.props.getStatistical(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListServicesProvided(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
            this.props.getStatistical(query);
            this.setState({ selectedValues: [] })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }

        if (name === 'selected_at') {
            if (value && value.length > 0) {
                query.selected_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.selected_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.selected_date_start;
                delete query.selected_date_end;
            }
        }

        if (name === 'customer_source_id') {
            if (value && value.length > 0) {
                let string = value.join(',');
                query.customer_source_id = string;
            } else {
                delete query.customer_source_id;
            }
        }

        delete query.created_at;
        delete query.selected_at;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificServicesProvided(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id, loadingDetail: true })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListServicesProvided(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    handleSelectAll = () => {
        const { customer_source_active_list } = this.props;
        const allValues = customer_source_active_list.map(item => item.id);
        this.onChangeFilter('customer_source_id', allValues);
        this.setState({ selectedValues: allValues });
    }

    handleDeselect = (deselectedValue) => {
        const updatedValues = this.state.selectedValues.filter(value => value !== deselectedValue);
        this.setState({ selectedValues: updatedValues });
    }

    render() {
        var { services_provided_list, pagination,
            service_active_list, customer_source_active_list, statistical
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenDetail,
            currentData, current_id,
            currentCustomerData,
            isOpenCustomerDetail,
            currentCustomerId,
            loadingDetail
        } = this.state;
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));
        const service = makeTree(service_active_list || [], 0);

        const columns = [
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (text, record) => {
                    var class_name = ''
                    var tooltip_title = 'Xem hồ sơ khách hàng';
                    if (record.customer_tasks == 'delete') {
                        class_name = 'is_delete_item';
                        tooltip_title = "Đã xóa"
                    }
                    if (record.customer_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title={tooltip_title} placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        {
                                            record.customer_avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.customer_avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;<span className={class_name}>{record.customer_name}</span>
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.customer_avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.customer_avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;<span className={class_name}>{record.customer_name}</span>
                                </>
                            )
                        }
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'customer_phone',
                width: '120px',
                key: 'customer_phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record.customer_id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Tên dịch vụ',
                dataIndex: 'service_name',
                key: 'service_name',
                render: (text, record) => {
                    if (text) {
                        if (checkPermission('services_provided', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết dịch vụ" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {text}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {text}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: "Giá gốc",
                dataIndex: "regular_price",
                width: "120px",
                key: 'regular_price',
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: "Giá bán",
                dataIndex: "sale_price",
                width: "120px",
                key: 'sale_price',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: "Số lượng",
                dataIndex: "quantity",
                width: "100px",
                key: 'quantity',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "120px",
                key: 'amount',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                        )
                    }
                }
            },
            {
                title: 'Loại dịch vụ',
                dataIndex: 'type',
                key: 'type',
                render: (text, record) => {
                    if (record.type == 'QUANTAM') {
                        return (
                            <Tag color="#ffb406">Dịch vụ quan tâm</Tag>
                        )
                    } else if (record.type == 'CHOT') {
                        return (<Tag color='#03C03C'>Dịch vụ chốt</Tag>)
                    } else {
                        return (<>N/A</>)
                    }
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày chốt',
                dataIndex: 'selected_at',
                key: 'selected_at',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, false)}</div>
                        )
                    }
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '140px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text)}</div>
                        )
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Dịch vụ" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={
                            query.created_date_start ||
                            query.created_date_end ||
                            query.selected_date_start ||
                            query.selected_date_end ||
                            query.type ||
                            query.service_id ||
                            query.customer_source_id
                        }
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        filters={
                            [
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <TreeSelect
                                    showSearch
                                    className="w-100"
                                    dropdownStyle={{ maxHeight: 450, overflow: 'auto' }}
                                    treeData={service}
                                    treeNodeFilterProp='title'
                                    allowClear
                                    placeholder="Dịch vụ"
                                    treeDefaultExpandAll
                                    defaultValue={query.interested_service_id ? parseInt(query.service_id) : null}
                                    onChange={(value) => this.onChangeFilter('service_id', value)}
                                />,
                                <Select
                                    className="w-100"
                                    placeholder="Nguồn khách hàng"
                                    mode="multiple"
                                    allowClear
                                    showArrow
                                    showSearch
                                    value={this.state.selectedValues.length > 0 ? this.state.selectedValues : query.customer_source_id ? query.customer_source_id.split(',').map(Number) : []}
                                    onDeselect={(deselectedValue) => this.handleDeselect(deselectedValue)}
                                    onChange={(value) => this.onChangeFilter('customer_source_id', value)}
                                    defaultValue={query.customer_source_id ? query.customer_source_id.split(',').map(Number) : []}
                                    onSelect={(value) => this.setState({ selectedValues: [...this.state.selectedValues, value] })}
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    dropdownRender={menu => (
                                        <>
                                            {menu}
                                            <Divider style={{ margin: '8px 0' }} />
                                            <div style={{ padding: '2px 8px 8px 8px' }}>
                                                <Button className='w-100' type="dashed" onClick={this.handleSelectAll}>
                                                    Chọn tất cả
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                >
                                    {customer_source_active_list.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            ]
                        }
                    >
                        <BaseSelect
                            options={[
                                { value: 'CHOT', label: 'Dịch vụ đã chốt' },
                                { value: 'QUANTAM', label: 'Dịch vụ quan tâm' },
                            ]}
                            showSearch
                            placeholder="Loại dịch vụ"
                            className="table-button"
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('type', value)}
                            defaultValue={query.type ? query.type : null}
                        />
                        <RangePicker
                            format="DD/MM/YYYY"
                            placeholder={['Ngày chốt', 'đến ngày']}
                            className="table-button"
                            style={{ width: '220px' }}
                            onChange={(value) => this.onChangeFilter('selected_at', value)}
                            defaultValue={[query.selected_date_start ? moment(query.selected_date_start, 'YYYY-MM-DD') : null, query.selected_date_end ? moment(query.selected_date_end, 'YYYY-MM-DD') : null]}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Row gutter={16} className="mb-2" justify="space-between" style={{ margin: 0 }}>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Dịch vụ chốt"
                            value={statistical?.total_completed_services || 0}
                            precision={0}
                        />
                    </Card>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Dịch vụ quan tâm"
                            value={statistical?.total_interest_services || 0}
                            precision={0}
                        />
                    </Card>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Giá gốc"
                            value={statistical?.total_regular_price || 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Giá bán"
                            value={statistical?.total_sale_price ?? 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Mức giảm giá"
                            value={statistical?.discount_rate || 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                    <Card className="services-provided-list-statistic">
                        <StatisticNumber
                            title="Thành tiền"
                            value={statistical?.total_amount || 0}
                            precision={0}
                            unit="đ"
                        />
                    </Card>
                </Row>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={services_provided_list || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        moduleKey="services_provided"
                        rowSelection={false}
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>
                <ServicesProvidedDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        services_provided_list: state.ServicesProvidedReducer.services_provided_list,
        pagination: state.ServicesProvidedReducer.pagination,
        service_active_list: state.ServiceReducer.service_active_list,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        statistical: state.ServicesProvidedReducer.statistical
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListServicesProvided: (filter) => dispatch(getListServicesProvided(filter)),
        getSpecificServicesProvided: (id) => dispatch(getSpecificServicesProvided(id)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getStatistical: filter => dispatch(getStatistical(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ServicesProvidedList' })(ServicesProvidedList));

