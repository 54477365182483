import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { } from '@ant-design/icons';
import { Drawer, Input, Space, Button, Row, Col, DatePicker, Card, Tag, InputNumber } from 'antd';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import { CustomerCardStatus, customDataImageUpload, customerGender } from '../../utils/helpers';
import UploadImage from '../../components/UploadImage';
import CustomerFamilyList from './CustomerFamilyList';
import { NotificationManager } from 'react-notifications';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { createCustomer } from '../../redux/actions/CustomerActions';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchManagementActions';

class CustomerAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            districts: [],
            wards: [],
            avatar: [],
            banner_ads: [],
            isResetUpload: true,
            customer_family: []
        };
    }

    componentDidMount() {
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();
        this.props.getBranchActive();
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            delete values.banner_ads
            if (!err) {
                try {
                    this.setState({ isLoading: true })
                    const {
                        avatar,
                        banner_ads,
                        customer_family } = this.state;

                    values.avatar = customDataImageUpload(avatar);
                    values.banner_ads = customDataImageUpload(banner_ads);

                    values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                    values.customer_family = customer_family;
                    values.mode = "from_manual"

                    console.log(values)
                    await this.props.createCustomer(values);
                    this.onClose();
                    this.props.form.resetFields();
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
            avatar: [],
            banner_ads: [],
            isResetUpload: true,
        });
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.resetFields(['district_code', 'wards_code']);
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.resetFields(['wards_code']);
                this.setState({ wards: res.data })
            })
        }
    }

    onChangeUpload = (stateKey, data, message) => {
        this.setState({ isResetUpload: false, [stateKey]: data });
        NotificationManager.success(message);
    }

    // Sử dụng hàm onChangeUpload cho từng trạng thái cụ thể
    onChangeAvatar = (data) => this.onChangeUpload('avatar', data, 'Avatar complete.');
    onChangeBannerAds = (data) => this.onChangeUpload('banner_ads', data, 'Banner ads complete.');


    getItems = data => {
        this.setState({ customer_family: data })
    }

    render() {
        var {
            districts,
            wards,
            isResetUpload,
            isLoading
        } = this.state;
        var {
            visible,
            customerClassActiveList,
            customerStatusActiveList,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            customer_source_active_list,
            branchActiveList
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title="Thêm khách hàng mới"
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Thêm
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Card title={<><Tag color='#0b2b33'>1</Tag> Thông tin khách hàng</>} size='small'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Tên khách hàng">
                                                {getFieldDecorator('full_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: ""
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Cơ sở">
                                                {getFieldDecorator('branch_management_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseSelect
                                                        options={branchActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        placeholder="Chọn một bản ghi"
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Giới tính">
                                                {getFieldDecorator("gender", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseSelect
                                                        options={customerGender || []}
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Số điện thoại">
                                                {getFieldDecorator("phone", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <Input placeholder='0978015988' />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nguồn">
                                                {getFieldDecorator("customer_source_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseSelect
                                                        options={customer_source_active_list || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Năm sinh">
                                                {getFieldDecorator('birthday', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <DatePicker
                                                        picker="year"
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label='Hạng khách hàng'>
                                                {getFieldDecorator("customer_class_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={customerClassActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mã số thẻ cũ">
                                                {getFieldDecorator("card_number", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Trạng thái thẻ">
                                                {getFieldDecorator('card_status', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: 'none'
                                                })(
                                                    <BaseRadioList
                                                        options={CustomerCardStatus}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {/* <Col xs={24} md={8}>
                                            <Form.Item label="Doanh số">
                                                {getFieldDecorator("revenue", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: 0
                                                })(
                                                    <InputNumber
                                                        className='w-100'
                                                        addonAfter="vnđ"
                                                        step={100000}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col> */}
                                    </Row>
                                </Col>
                                {/* <Col xs={24} md={6} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Form.Item label="&nbsp;">
                                        {getFieldDecorator('avatar', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                uploadText="Avatar"
                                                multiple={false}
                                                maxCount={1}
                                                onChangeData={this.onChangeAvatar}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Card>
                        <Row gutter={16} className='mt-3'>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: ""
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Banner quảng cáo" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    {getFieldDecorator('banner_ads', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeBannerAds}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Card title={<><Tag color='#0b2b33'>2</Tag> Tương tác</>} size='small' className='mt-4'>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Trạng thái'>
                                        {getFieldDecorator("customer_status_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerStatusActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Loại tương tác'>
                                        {getFieldDecorator("fb_Interactive_type_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerInteractiveTypeActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Trạng thái tương tác'>
                                        {getFieldDecorator("fb_Interactive_status_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerInteractiveStatusActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tên facebook">
                                        {getFieldDecorator('fb_name', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(<Input />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Link facebook">
                                        {getFieldDecorator("fb_link", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Link fanpage">
                                        {getFieldDecorator("fb_source", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card title={<><Tag color='#0b2b33'>3</Tag> Thông tin nhân khẩu</>} size='small' className='mt-4'>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tỉnh/Thành phố">
                                        {getFieldDecorator("province_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={list_provinces || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadDistricts(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Quận/Huyện">
                                        {getFieldDecorator("district_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={districts || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadWards(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Phường/Xã">
                                        {getFieldDecorator("wards_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={wards || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Địa chỉ">
                                        {getFieldDecorator('address', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: ""
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <CustomerFamilyList getItems={(data) => this.getItems(data)} />
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerClassActiveList: state.CustomerClassReducer.customerClassActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        users: state.config.users,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        branchActiveList: state.BranchManagementReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomer: (data) => dispatch(createCustomer(data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerAddForm' })(CustomerAddForm));