import {
    GET_LIST_SERVICES_PROVIDED,
    GET_SPECIFIC_SERVICES_PROVIDED,
    UPDATE_SPECIFIC_SERVICES_PROVIDED,
    CREATE_NEW_SERVICES_PROVIDED,
    REMOVE_SERVICES_PROVIDED,
    GET_STATISTICAL_ERVICES_PROVIDED
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    services_provided_list: [],
    currentServicesProvided: null,
    statistical: null,
    pagination: {
        currentPage: 1,
        total: 0, // total records
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_SERVICES_PROVIDED: {
            return {
                ...state,
                services_provided_list: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }

        case GET_SPECIFIC_SERVICES_PROVIDED: {
            return { ...state, currentServicesProvided: action.payload }
        }
        case CREATE_NEW_SERVICES_PROVIDED: {
            return {
                ...state,
                services_provided_list: [action.payload, ...state.services_provided_list],
                total: state.total + 1
            }
        }
        case UPDATE_SPECIFIC_SERVICES_PROVIDED: {
            let index = state.services_provided_list.findIndex((item) => {
                return item.id == action.payload.id;
            });
            let temp = [...state.services_provided_list];
            temp[index] = action.payload;
            return { ...state, services_provided_list: temp }
        }
        case REMOVE_SERVICES_PROVIDED: {
            let temp = state.services_provided_list.filter(item => {
                //console.log(action.payload)
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, services_provided_list: temp }
        }
        case GET_STATISTICAL_ERVICES_PROVIDED:
            return {
                ...state,
                statistical: action.payload
            };
        default: return { ...state };
    }
}
